import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthContext, { SimpleContext } from "../Context/AuthContext";

export { PrivateRoute };

function PrivateRoute({ children }) {
    const { user } = useContext(SimpleContext)

    if ( user === null ) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" />
    }

    // authorized so return child components
    return children;

}