import React, { useContext, useEffect, useState } from 'react'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Collapse  } from '@mui/material';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Modal } from "react-bootstrap";


import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { SimpleContext } from '../Context/AuthContext'
import { BaseURL } from '../Constants/ConstantURL'
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import moment from "moment";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert  ref={ref} variant="filled" {...props} />;
  });
  
function AdminsPage() {

  const {authtoken , setis_sidebar } = useContext(SimpleContext)


  const [create_admin_modal, setcreate_admin_modal] = useState(false)
  const [edit_id, setedit_id] = useState("")

  const [all_admin_details, setall_admin_details] = useState([])

  const [loading, setloading] = useState(false)
  const [alert,setalert] = useState({
    open:false,
    msg:"",
    severity:""
  })


  const validationSchema = Yup.object().shape({

    username : Yup.string().required('Username is Required'),
    password : Yup.string().required('Password is Required').min(5,'Minimum 5 letters Required'),
    email : Yup.string().required('Password is Required').email('please Fill Valid email'),
    firstname : Yup.string(),
    lastname : Yup.string(),
    mobile : Yup.string(),
    date_of_birth : Yup.string(),
    gender : Yup.string(),
    address : Yup.string(),
    is_active : Yup.bool(),

  
})


const handleClose = () => {setcreate_admin_modal(false);reset();setedit_id("")};
const handleShow = () => {setcreate_admin_modal(true)};

const {register,handleSubmit,reset,formState: { errors }} = useForm({resolver: yupResolver(validationSchema)});

  

  const AlertClose = (event, reason) => {
    setalert({open:false,message:""})
  };


    useEffect(() => {
        setis_sidebar(false)
        All_admin_users()
    }, [])


    const All_admin_users = () => {
        var data = {
            'action' : 'Fetch_Admin_Data'
        }
        axios.post(`${BaseURL}/user_details/users/`,data,
    
            { headers: {"Authorization" : `Bearer ${authtoken.access}`} }
    
        )
        .then((res) => {
            if (res.data.status === 200) {
                console.log(res.data.message)
                setall_admin_details(res.data.message)
            }
        })
      }


      
const Delete_User = (id,user) => {
    
    if (window.confirm(`Do You want to Delete User ${user}`)) {
        var data = {
            'id' : id,
            'action' : 'Delete_user'
        }
        axios.post(`${BaseURL}/user_details/users/`,data,
    
            {
                headers: {"Authorization" : `Bearer ${authtoken.access}`} 
            }
    
        )
        .then((res) => {
            if (res.data.status === 200) {
                setalert({open:true,msg:res.data.message,severity:"success"})
                All_admin_users()
            }
            else{
                setalert({open:true,msg:res.data.message,severity:"error"})
            }
        })
    }
  
}


const StatusChange  = (id) => {
    var data = {
        "id" : id,
        "action" : "Change_Admin_Status"
    }

    axios.post(`${BaseURL}/user_details/users/`,data,
    
    { headers: {"Authorization" : `Bearer ${authtoken.access}`} }

    )
    .then((res) => {
        if (res.data.status === 200) {
            setalert({open:true,msg:res.data.message,severity:"success"})
            All_admin_users()
        }
        else{
            setalert({open:true,msg:res.data.message,severity:"success"})

        }
    })
    .catch((error) => {
        setalert({open:true,msg:"Something Went Wrong",severity:"success"})

    })
}



      const EditData = (id) => {
   
        if (id === null) {
            reset()
            handleShow()
        }
    
        else {
             var data = {
                "id" : id,
                "action" : "Fetch_Admin_Data"
             }
             axios.post(`${BaseURL}/user_details/users/`,data,
    
            { headers: {"Authorization" : `Bearer ${authtoken.access}`} }
    
            )
            .then((res) => {
                reset({
                    username : res.data.message[0].username,
                    password : res.data.message[0].password,
                    is_active : res.data.message[0].is_active,
                    email : res.data.message[0].email ,
                    firstname : res.data.message[0].first_name ,
                    lastname : res.data.message[0].last_name ,
                    mobile : res.data.message[0].mobile ,
                    date_of_birth : res.data.message[0].dob ,
                    gender : res.data.message[0].gender ,
                    address : res.data.message[0].address ,
                })
                setedit_id(res.data.message[0].id)
                handleShow()
            })
        }
      
    }




      const Submit = (data) => {
        setloading(true)
        var data = {
            "username" : data.username,
            "password" : data.password,
            "first_name" : data.firstname,
            "last_name" : data.lastname,
            "email" : data.email,
            "address" : data.address,
            "dob" : data.date_of_birth,
            "gender" : data.gender,
            "mobile" : data.mobile,
            "action" : "Add/Edit",
            "id" : edit_id,
            "is_admin" : true,
            "is_active" : data.is_active
        }
    
        axios.post(`${BaseURL}/user_details/users/`,data,
    
                {
                     headers: {"Authorization" : `Bearer ${authtoken.access}`} 
                }
    
            )
            .then((res) => {
                if (res.data.status === 200) {
    
                    setloading(false)
                    handleClose()
                    setalert({open:true,msg:res.data.message,severity:"success"})
                    All_admin_users()
                    
                }
                else {
    
                    setloading(false)
                    reset()
                    setalert({open:true,msg:res.data.message,severity:"error"})
    
                }
            })
            .catch((error) => {
                setloading(false)
                if (error.response.data.username != undefined) {
                    setalert({open:true,msg:error.response.data.username,severity:"error"})
                }
                else if (error.response.data.password != undefined) {
                    setalert({open:true,msg:error.response.data.password,severity:"error"})
                }
                else {
                    setalert({open:true,msg:"Somethin went wrong",severity:"error"})
                }
                // setalert({open:true,msg:error.response.data.username,severity:"error"})
    
                console.log(error.response.data.password)
            })
    
    }



  return (
    <div>
  <div id="wrapper">

    <div className="content-page">
      <div className="content mt-4">
        
        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-between">
                    <div className='col-8'></div>
                    <div className="col-md-4" style={{ textAlign:"right"}}>
                      <div className="mt-3 mt-md-0">
                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={()=>EditData(null)}>Add Admin</button>
                      </div>
                    </div>
             
                  </div>
                </div>
              </div> 
            </div>
          </div> */}


            <div className="row">
              <div className="col-12">
                <div className="col-xl-12 col-md-3">
                  <div className="card">
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-6'>
                                <h4 className="header-title mt-0">
                                    <i className="mdi mdi-filter-outline" />
                                        Filter{" "}
                                </h4>
                            </div>
                            <div className='col-6' style={{ textAlign:"right"}}>
                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={()=>EditData(null)}>Add Admin</button>

                            </div>
                        </div>
                     

                      {/* <div class="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show" role="alert">
                            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
                            This is a primary alert—check it out!
                        </div> */}

                      <hr />
                      <div className="row">
                        <div className="col-md-3 col-sm-12">
                          <label
                            htmlFor="example-palaceholder"
                            className="form-label"
                          >
                            Name :
                          </label>
                          <input
                            type="text"
                            id="example-palaceholder"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-6 col-sm-12"
                          style={{ textAlign: "right" }}
                        ></div>
                        <div
                          className="col-md-6 col-sm-12 searchbtndiv"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            type="button"
                            className="btn btn-danger waves-effect waves-light"
                            style={{ marginRight: "5px" }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            

         
          <div className="row">


            { all_admin_details.length === 0 ? 
            <div className="col-12">
            <div className="card">
              <div className="text-center card-body">
                    <p>There is No Data Found</p>
              </div>
            </div>
          </div>
           : 
           <>
           { all_admin_details.map((value,key) => (
                <div className="col-xl-4" key={key}>
                <div className="card">
                  <div className="text-center card-body">
             
                    <div>

                        { value.user_prof_image === null ? 
                      <img src="/assets/images/Hello_Health/avatar.jpg" className="rounded-circle avatar-xl img-thumbnail mb-2" alt="profile-image" />
                      :
                      <img src={ `${BaseURL}${value.user_prof_image}`} className="rounded-circle avatar-xl img-thumbnail mb-2" alt="profile-image" />    
                    }
  
                      <p class="form_text1 text-muted font-13"><span><strong>Username</strong></span><strong>:</strong><span className="ms-2">{value.username}</span>  </p>
                      <p class="form_text1 text-muted font-13"><span><strong>{value.is_superuser === true ? 'Super User' : 'Admin'}</strong></span></p>
  
  
  
  
                      <div className="text-start mt-2">
  
                      <p class="form_text1 text-muted font-13"><span><strong>First Name</strong></span><strong>:</strong><span className="ms-2">{value.first_name}</span>  </p>
                      <p class="form_text1 text-muted font-13"><span><strong>Last Name</strong></span><strong>:</strong><span className="ms-2">{value.last_name}</span>  </p>
                      <p class="form_text1 text-muted font-13"><span><strong>Mobile</strong></span><strong>:</strong><span className="ms-2">{value.mobile}</span>  </p>
                      <p class="form_text1 text-muted font-13"><span><strong>Email</strong></span><strong>:</strong><span className="ms-2">{value.email}</span>  </p>
                      <p class="form_text1 text-muted font-13"><span><strong>DOB</strong></span><strong>:</strong><span className="ms-2">{value.dob}</span>  </p>
                      <p class="form_text1 text-muted font-13"><span><strong>Gender</strong></span><strong>:</strong><span className="ms-2">{value.gender}</span>  </p>
                      <p class="form_text1 text-muted font-13"><span><strong>Address</strong></span><strong>:</strong><span className="ms-2">{value.address}</span>  </p>
  
                      </div>
  


                        { value.is_superuser === true ? '' : 
                        
                        <div className='row mt-3'>
                        <div className='col-6'>
                          
                        </div>
                        <div className='col-3'>
                        { value.is_active === true ? 
                            <button type="button" className="btn btn-success  waves-effect waves-light" onClick={()=>StatusChange(value.id)}>Active</button>
                            :
                            <button type="button" className="btn btn-danger  waves-effect waves-light" onClick={()=>StatusChange(value.id)}>Block</button>
                            }
                        </div>
                        <div className='col-3'><button type="button" className="btn btn-danger  waves-effect waves-light" onClick={()=>Delete_User(value.id,value.username)} >Delete</button></div>

                        {/* <div className='col-3'><button type="button" className="btn btn-warning  waves-effect waves-light" onClick={()=>EditData(value.id)} >Edit</button></div> */}
                  </div>
                  
                  
                  }
  
  
                     
                    </div>
                  </div>
                </div>
              </div>
           ))}
           </>
           }



            {/* <div className="col-xl-4">
              <div className="card">
                <div className="text-center card-body">
           
                  <div>
                    <img src="assets/images/users/user-10.jpg" className="rounded-circle avatar-xl img-thumbnail mb-2" alt="profile-image" />

                    <p class="form_text1 text-muted font-13"><span><strong>Username</strong></span><strong>:</strong><span className="ms-2">aaa</span>  </p>




                    <div className="text-start mt-2">

                    <p class="form_text1 text-muted font-13"><span><strong>First Name</strong></span><strong>:</strong><span className="ms-2">aaa</span>  </p>
                    <p class="form_text1 text-muted font-13"><span><strong>Last Name</strong></span><strong>:</strong><span className="ms-2">aaa</span>  </p>
                    <p class="form_text1 text-muted font-13"><span><strong>Mobile</strong></span><strong>:</strong><span className="ms-2">aaa</span>  </p>
                    <p class="form_text1 text-muted font-13"><span><strong>Email</strong></span><strong>:</strong><span className="ms-2">aaa</span>  </p>
                    <p class="form_text1 text-muted font-13"><span><strong>DOB</strong></span><strong>:</strong><span className="ms-2">aaa</span>  </p>
                    <p class="form_text1 text-muted font-13"><span><strong>Gender</strong></span><strong>:</strong><span className="ms-2">aaa</span>  </p>
                    <p class="form_text1 text-muted font-13"><span><strong>Address</strong></span><strong>:</strong><span className="ms-2">aaa dfsdgdg</span>  </p>

                    </div>




                    <div className='row mt-3'>
                        <div className='col-3'><button type="button" className="btn btn-success  waves-effect waves-light">Active</button></div>
                        <div className='col-3'></div>
                        <div className='col-3'><button type="button" className="btn btn-danger  waves-effect waves-light">Delete</button></div>
                        <div className='col-3'><button type="button" className="btn btn-warning  waves-effect waves-light">Edit</button></div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>  */}
        
          </div>
          
         
        </div>




      </div> 
     
   
    </div>


    <Modal
        show={create_admin_modal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign:"center"}}>Create Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
   
      <div className="row">


        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">Username</label>
            <input type="text" {...register('username')} name='username' placeholder='username'
            className={`form-control ${errors.username ? 'is-invalid' : ''}`}/>
            <div className="invalid-feedback">{errors.username?.message}</div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">Password</label>
            <input type="text" {...register('password')} name='password' placeholder='password'
            className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">Email</label>
            <input type="email" {...register('email')} name='email' placeholder='Email'
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}/>
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">First Name</label>
            <input type="text" {...register('firstname')} name='firstname' placeholder='First Name'
            className={`form-control ${errors.firstname ? 'is-invalid' : ''}`}/>
            <div className="invalid-feedback">{errors.firstname?.message}</div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">Last Name</label>
            <input type="text" {...register('lastname')} name='lastname' placeholder='Last Name'
            className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}/>
            <div className="invalid-feedback">{errors.lastname?.message}</div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">Mobile</label>
            <input type="text" {...register('mobile')} name='mobile' placeholder='Mobile'
            className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}/>
            <div className="invalid-feedback">{errors.mobile?.message}</div>
          </div>
        </div>


        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">DOB</label>
            <input type="date" {...register('date_of_birth')} name='date_of_birth' placeholder='Date Of Birth'
            className={`form-control ${errors.date_of_birth ? 'is-invalid' : ''}`}/>
            <div className="invalid-feedback">{errors.date_of_birth?.message}</div>
          </div>
        </div>


        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">Gender</label>
            <select  {...register('gender')} name='gender'  className={`form-control ${errors.gender ? 'is-invalid' : ''}`}  >
                <option selected value="" disabled>-- Choose Gender -- </option>
                <option  value="Male" > Male  </option>
                <option  value="Female" > Female </option>
            </select>
            <div className="invalid-feedback">{errors.gender?.message}</div>
          </div>
        </div>

        <div className="col-4">
        <div className="mb-3">
                <label className="form-check-label" htmlFor="checkbox-signin">Active</label>

                <div className="form-check">
                
                  <input type="checkbox" {...register('is_active')} name='is_active' placeholder='Username'
            className={`form-check-input mt-2 ${errors.is_active ? 'is-invalid' : ''}`} />
            <div className="invalid-feedback">{errors.is_active?.message}</div>
                  <label className="form-check-label mt-2" htmlFor="checkbox-signin">Is Active</label>
                </div>
              </div>
        </div> 

        <div className="col-12">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">Address</label>
            <textarea rows={5} type="text" {...register('address')} name='address' placeholder='Address'
            className={`form-control ${errors.address ? 'is-invalid' : ''}`}/>
            <div className="invalid-feedback">{errors.address?.message}</div>
          </div>
        </div>




      </div>

      {/* <div className="row">
        <div className="col-6">
        <div className="mb-3">
                <div className="form-check">
                
                  <input type="checkbox" {...register('is_active')} name='is_active' placeholder='Username'
            className={`form-check-input ${errors.is_active ? 'is-invalid' : ''}`} />
            <div className="invalid-feedback">{errors.is_active?.message}</div>
                  <label className="form-check-label" htmlFor="checkbox-signin">Is Active</label>
                </div>
              </div>
        </div> 
       
      </div> */}
    

     
      
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary waves-effect" onClick={handleClose}>Close</button>
      {/* <button type="button" className="btn btn-info waves-effect waves-light" onClick={handleSubmit(Submit)}>Save changes</button> */}
      {loading? <button className="btn btn-info waves-effect waves-light" disabled> <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> Saving...</button> :
        <button className="btn btn-info waves-effect waves-light" type="submit" onClick={handleSubmit(Submit)} > Save </button>}
    </div>


        </Modal.Body>
        
      </Modal>


  
  </div>

  
  <Snackbar open={alert.open} TransitionComponent={Collapse} anchorOrigin={{"vertical":"top","horizontal":"center"}} autoHideDuration={3000} onClose={AlertClose} >
                
                <Alert onClose={AlertClose}  severity={alert.severity} >
                    {alert.msg}
                </Alert>
              </Snackbar>

</div>

  )
}

export default AdminsPage