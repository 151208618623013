import React from 'react'

function Footer() {
  return (
    <footer className="footer">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-6">
        © Copyright 2023 <a href="/">Hello Health</a>
      </div>
      <div className="col-md-6">
        {/* <div className="text-md-end footer-links d-none d-sm-block">
          <a href="/">About Us</a>
          <a href="/">Help</a>
          <a href="/">Contact Us</a>
        </div> */}
      </div>
    </div>
  </div>
</footer>

  )
}

export default Footer