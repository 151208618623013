import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { SimpleContext } from '../Context/AuthContext'


function Header() {
    const {Logout} = useContext(SimpleContext)
    const { user , is_sidebar , setis_sidebar } = useContext(SimpleContext)




  return (
    <div className="navbar-custom" >
    <ul className="list-unstyled topnav-menu float-end mb-0">
      {/* <li className="d-none d-lg-block">
        <form className="app-search">
          <div className="app-search-box">
            <div className="input-group">
              <input type="text" className="form-control" placeholder="Search..." id="top-search" />
              <button className="btn input-group-text" type="submit">
                <i className="fe-search" />
              </button>
            </div>
            <div className="dropdown-menu dropdown-lg" id="search-dropdown">
             
              <div className="dropdown-header noti-title">
                <h5 className="text-overflow mb-2">Found 22 results</h5>
              </div>
             
              <a href="/" className="dropdown-item notify-item">
                <i className="fe-home me-1" />
                <span>Analytics Report</span>
              </a>
             
              <a href="/" className="dropdown-item notify-item">
                <i className="fe-aperture me-1" />
                <span>How can I help you?</span>
              </a>
             
              <a href="/" className="dropdown-item notify-item">
                <i className="fe-settings me-1" />
                <span>User profile settings</span>
              </a>
             
              <div className="dropdown-header noti-title">
                <h6 className="text-overflow mb-2 text-uppercase">Users</h6>
              </div>
              <div className="notification-list">
               
                <a href="/" className="dropdown-item notify-item">
                  <div className="d-flex align-items-start">
                    <img className="d-flex me-2 rounded-circle" src="assets/images/users/user-2.jpg" alt="/" height={32} />
                    <div className="w-100">
                      <h5 className="m-0 font-14">Erwin E. Brown</h5>
                      <span className="font-12 mb-0">UI Designer</span>
                    </div>
                  </div>
                </a>
               
                <a href="/" className="dropdown-item notify-item">
                  <div className="d-flex align-items-start">
                    <img className="d-flex me-2 rounded-circle" src="assets/images/users/user-5.jpg" alt="/" height={32} />
                    <div className="w-100">
                      <h5 className="m-0 font-14">Jacob Deo</h5>
                      <span className="font-12 mb-0">Developer</span>
                    </div>
                  </div>
                </a>
              </div>
            </div> 
          </div>
        </form>
      </li> */}
      {/* <li className="dropdown d-inline-block d-lg-none">
        <a className="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-bs-toggle="dropdown" href="/" role="button" aria-haspopup="false" aria-expanded="false">
          <i className="fe-search noti-icon" />
        </a>
        <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0">
          <form className="p-3">
            <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
          </form>
        </div>
      </li> */}
      {/* <li className="dropdown notification-list topbar-dropdown">
        <a className="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="/" role="button" aria-haspopup="false" aria-expanded="false">
          <i className="fe-bell noti-icon" />
          <span className="badge bg-danger rounded-circle noti-icon-badge">9</span>
        </a>
        <div className="dropdown-menu dropdown-menu-end dropdown-lg">
         
          <div className="dropdown-item noti-title">
            <h5 className="m-0">
              <span className="float-end">
                <a href="/" className="text-dark">
                  <small>Clear All</small>
                </a>
              </span>Notification
            </h5>
          </div>
          <div className="noti-scroll" data-simplebar>
           
            <a href="/" className="dropdown-item notify-item active">
              <div className="notify-icon">
                <img src="assets/images/users/user-1.jpg" className="img-fluid rounded-circle" alt="/" /> </div>
              <p className="notify-details">Cristina Pride</p>
              <p className="text-muted mb-0 user-msg">
                <small>Hi, How are you? What about our next meeting</small>
              </p>
            </a>
           
            <a href="/" className="dropdown-item notify-item">
              <div className="notify-icon bg-primary">
                <i className="mdi mdi-comment-account-outline" />
              </div>
              <p className="notify-details">Caleb Flakelar commented on Admin
                <small className="text-muted">1 min ago</small>
              </p>
            </a>
           
            <a href="/" className="dropdown-item notify-item">
              <div className="notify-icon">
                <img src="assets/images/users/user-4.jpg" className="img-fluid rounded-circle" alt="/" /> </div>
              <p className="notify-details">Karen Robinson</p>
              <p className="text-muted mb-0 user-msg">
                <small>Wow ! this admin looks good and awesome design</small>
              </p>
            </a>
           
            <a href="/" className="dropdown-item notify-item">
              <div className="notify-icon bg-warning">
                <i className="mdi mdi-account-plus" />
              </div>
              <p className="notify-details">New user registered.
                <small className="text-muted">5 hours ago</small>
              </p>
            </a>
           
            <a href="/" className="dropdown-item notify-item">
              <div className="notify-icon bg-info">
                <i className="mdi mdi-comment-account-outline" />
              </div>
              <p className="notify-details">Caleb Flakelar commented on Admin
                <small className="text-muted">4 days ago</small>
              </p>
            </a>
           
            <a href="/" className="dropdown-item notify-item">
              <div className="notify-icon bg-secondary">
                <i className="mdi mdi-heart" />
              </div>
              <p className="notify-details">Carlos Crouch liked
                <b>Admin</b>
                <small className="text-muted">13 days ago</small>
              </p>
            </a>
          </div>
        
          <a href="/" className="dropdown-item text-center text-primary notify-item notify-all">
            View all
            <i className="fe-arrow-right" />
          </a>
        </div>
      </li> */}
      <li className="dropdown notification-list topbar-dropdown">
        <a className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" href="/" role="button" aria-haspopup="false" aria-expanded="false">
          <img src="/assets/images/Hello_Health/avatar.jpg" alt="/" className="rounded-circle" />
          <span className="pro-user-name ms-1">
            {user.username} <i className="mdi mdi-chevron-down" /> 
          </span>
        </a>
        <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
         
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">Welcome !</h6>
          </div>
         
          {/* <Link to="/profile" className="dropdown-item notify-item">
            <i className="fe-user" />
            <span>My Account</span>
          </Link> */}
         
          {/* <a href="/" className="dropdown-item notify-item">
            <i className="fe-lock" />
            <span>Lock Screen</span>
          </a> */}
          <div className="dropdown-divider" />
         
          <div className="dropdown-item notify-item" style={{ cursor:"pointer"}} onClick={()=>Logout(" You Are Succsfully Loged Out")}>
            <i className="fe-log-out" />
            <span >Logout</span>
          </div>
        </div>
      </li>
      {/* <li className="dropdown notification-list">
        <a href="/" className="nav-link right-bar-toggle waves-effect waves-light">
          <i className="fe-settings noti-icon" />
        </a>
      </li> */}
    </ul>
   
    <div className="logo-box">
      {/* <a href="/" className="logo logo-light text-center">
        <span className="logo-sm">
          <img src="/assets/images/Hello_Health/logo-img.png" alt="/" height={22} />
        </span>
        <span className="logo-lg">
          <img src="/assets/images/Hello_Health/logo-img.png" alt="/" height={30} />
        </span>
      </a> */}
      <a href="/" className="logo logo-dark text-center">
        <span className="logo-sm">
          <img src="/assets/images/Hello_Health/logo-img.png" alt="/" height={25} />
        </span>
        <span className="logo-lg">
          <img src="/assets/images/Hello_Health/logo-img.png" alt="/" height={40} />
        </span>
      </a>
    </div>
    <ul className="list-unstyled topnav-menu topnav-menu-left mb-0">
      <li onClick={()=>setis_sidebar(!is_sidebar)}>
        <button className="button-menu-mobile disable-btn waves-effect" >
          <i className="fe-menu" />
        </button>
      </li>
      <li>
        <h4 className="page-title-main">Dashboard</h4>
      </li>
    </ul>
    <div className="clearfix" /> 
  </div>
  )
}

export default Header