import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import { SimpleContext } from '../Context/AuthContext'

function Outlet_Connection() {

  const { is_sidebar } = useContext(SimpleContext)

  useEffect(() => {
    console.log( is_sidebar )
  }, [])
  
  return (
    <div className={ is_sidebar === true ? 'sidebar-enable' : ''}>
        <Header />
        <Sidebar />
        <Outlet />
        <Footer />
    </div>
  )
}

export default Outlet_Connection