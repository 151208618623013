import React, { useContext, useEffect, useState } from 'react'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Collapse  } from '@mui/material';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { SimpleContext } from '../Context/AuthContext'
import jwt_decode from 'jwt-decode'
import { BaseURL } from '../Constants/ConstantURL'

function LoginPage() {

    let navigate = useNavigate()


    let { state } =  useLocation()
    


    const {setauthtoken} = useContext(SimpleContext)
    const {setUser} = useContext(SimpleContext)

    const [loading, setloading] = useState(false)
    const [alert,setalert] = useState({
      open:false,
      msg:"",
      severity:""
    })

    const validationSchema = Yup.object().shape({
      username : Yup.string().required('Username Is Required'),
      password : Yup.string().required('Password Is Required')
    })
  
    const {register,handleSubmit,reset,formState: { errors }} = useForm({resolver: yupResolver(validationSchema)});
    

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert  ref={ref} variant="filled" {...props} />;
    });

    const User_login = (data) => {

        setloading(true)
        var data = {
            "username" : data.username,
            "password" : data.password
        }
        axios.post(`${BaseURL}/user_details/get_token/`,data)
        .then((res) => {
            console.log(res)
            if (res.request.status === 200) {
                console.log(res.data.access)
              // console.log(jwt_decode(res.data.access))

                if (jwt_decode(res.data.access).is_admin === false && jwt_decode(res.data.access).is_superuser === false){
                   setalert({open:true,msg:"No Account With This User",severity:"error"})
                   setloading(false)
                   reset()
                }
                else
                {
                  setloading(false)
                  setauthtoken(res.data)
                  setUser(jwt_decode(res.data.access))
                  reset()
                  localStorage.setItem('authTokens',JSON.stringify(res.data))
                  return navigate('/',{ state : { msg : "Succesfully Loged in"}})
                }
                
                // setloading(false)
                // setauthtoken(res.data)
                // setUser(jwt_decode(res.data.access))
                // reset()
                // localStorage.setItem('authTokens',JSON.stringify(res.data))
                // return navigate('/',{ state : { msg : "Succesfully Loged in"}})


            }
            else{
                setloading(false)
                setalert({open:true,msg:res.data.message,severity:"error"})
                reset()
            }

        })
        .catch((error) => {

            setloading(false)
            reset()
            if (error.message === "Network Error") {
              setalert({open:true,msg:error.message,severity:"error"})
            }
            else if (error.message === "Request failed with status code 401") {
              setalert({open:true,msg:"No active account found with the given credentials",severity:"error"})
            }
            else{
              setalert({open:true,msg:"Something Went Wrong",severity:"error"})
            }
           
        })
    }

    const AlertClose = (event, reason) => {
      setalert({open:false,message:"",severity:""})
    };


    useEffect(() => {
      const timer = setTimeout(() => {
        navigate('/login', { replace: true });
      }, 3000);
      return () => clearTimeout(timer);
    }, []);


   
  return (
   <div className="account-pages my-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-6 col-xl-4">
        
        <div className="text-center">   
            <img src="/assets/images/Hello_Health/logo-img.png" alt="/" height={45} className="mx-auto" />
          <p className="text-muted mt-2 mb-4">Welcome To Hello Health</p>


        </div>


        { 
        state === null ? '' : 
        <div class="alert alert-success bg-success text-white border-0" role="alert">
          <strong>{ state.msg }</strong>
        </div>
        }

        
        <div className="card">
          <div className="card-body p-4">
            <div className="text-center mb-4">
              <h4 className="text-uppercase mt-0">Sign In</h4>
            </div>
            <form autoComplete='off'>
                        <div className="mb-2">
                          <label htmlFor="emailaddress" className="form-label">username</label>
                          <input type="text" {...register('username')} name='username' placeholder='Username'
                          className={`form-control ${errors.username ? 'is-invalid' : ''}`}/>
                          <div className="invalid-feedback">{errors.username?.message}</div>
                        </div>
                        <div className="mb-2">
                          <label htmlFor="password" className="form-label">Password</label>
                          <input  type="password" {...register('password')} name='password' autoComplete='on'  placeholder='Password'
                     className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>
                     <div className="invalid-feedback">{errors.password?.message}</div>
                                            
                        </div>
                        {/* <div className="mb-1">
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="checkbox-signin" defaultChecked />
                            <label className="form-check-label" htmlFor="checkbox-signin">Remember me</label>
                          </div>
                        </div> */}
                        <div className="mb-1 d-grid text-center">
                          {loading? <button className="btn btn-primary" disabled> <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> Loading...</button> :
                          <button className="btn btn-primary" type="submit" onClick={handleSubmit(User_login)} > Log In </button>}
                        </div>
                      </form>
          </div> {/* end card-body */}
        </div>
        {/* end card */}
        <div className="row mt-3">
          <div className="col-12 text-center">
            {/* <p> <a href="/email-verification" className="text-muted ms-1"><i className="fa fa-lock me-1" />Forgot your password?</a></p> */}
          </div> {/* end col */}
        </div>
        {/* end row */}
      </div> {/* end col */}
    </div>
    {/* end row */}
  </div>



  <Snackbar open={alert.open} TransitionComponent={Collapse} anchorOrigin={{"vertical":"top","horizontal":"center"}} autoHideDuration={3000} onClose={AlertClose} >
                {/* <Alert  severity={alert.severity} >
                    {alert.msg}
                </Alert> */}
                <Alert onClose={AlertClose}  severity={alert.severity} >
                    {alert.msg}
                </Alert>
              </Snackbar>



  

  {/* end container */}
</div>

  )
}

export default LoginPage