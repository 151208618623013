import React, { useContext, useEffect, useState } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Collapse } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Modal } from "react-bootstrap";
import moment from "moment";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SimpleContext } from "../Context/AuthContext";
import { BaseURL } from "../Constants/ConstantURL";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} variant="filled" {...props} />;
});

function ChallengePage() {
  let navigate = useNavigate();

  const [image, setImage] = useState({ preview: null, raw: "" });

  const { authtoken, setis_sidebar } = useContext(SimpleContext);
  const [loading, setloading] = useState(false);
  const [promo_loading, setpromo_loading] = useState(false);
  const [alert, setalert] = useState({
    open: false,
    msg: "",
    severity: "",
  });

  const [all_challenges, setall_challenges] = useState([]);
  const [create_challenge_modal, setcreate_challenge_modal] = useState(false);
  const [promocode_modal, setpromocode_modal] = useState(false);
  const [promocodes, setpromocodes] = useState([]);

  const [edit_id, setedit_id] = useState("");

  const [promocode_id, setpromocode_id] = useState("");
  const [promo_code, setpromo_code] = useState("");
  const [challenge_id, setchallenge_id] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [discount_percentage, setdiscount_percentage] = useState("");
  const [onetime_use, setonetime_use] = useState(false);

  const validationSchema = Yup.object().shape({
    challengename_english: Yup.string().required(
      "Challenge Name English Is Required"
    ),
    challengename_arabic: Yup.string().required(
      "Challenge Name Arabic Is Required"
    ),
    challenge_description_arabic: Yup.string().required(
      "Challenge Description Arabic Is Required"
    ),
    challenge_description_english: Yup.string().required(
      "Challenge Description English Is Required"
    ),
    // no_of_steps_to_complete: Yup.string().required(
    //   "Steps is Is Required"
    // ),
    challenge_status: Yup.string().required("Challenge Status is Is Required"),
    challenge_image: Yup.mixed(),
    challenge_from_date: Yup.string().required("From date is Required"),
    challenge_to_date: Yup.string().required("To date is Required"),

    // promocode: Yup.string(),
    // fromdate: Yup.string().nullable().notRequired(),
    // todate: Yup.string().nullable().notRequired(),
    // onetimeuse: Yup.bool(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const handleClose = () => {
    setcreate_challenge_modal(false);
    reset();
    setedit_id("");
    setpromocode_id("");
    setchallenge_id("");
  };
  const handleShow = () => {
    setcreate_challenge_modal(true);
  };

  const AlertClose = (event, reason) => {
    setalert({ open: false, message: "", severity: "" });
  };

  useEffect(() => {
    setis_sidebar(false);
    All_Challenges();
    // Update_Statuses();
  }, []);

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      console.log(e.target.files[0]);
    }
  };

  const All_Challenges = () => {
    axios
      .get(`${BaseURL}/challenge_details/fetch_all_challenges/`)
      .then((res) => {
        console.log(res.data);
        setall_challenges(res.data);
      });
  };

  const Update_Statuses = () => {
    axios
      .get(`${BaseURL}/challenge_details/update_challenge_dates`)
      .then((res) => {
        if (res.data.status === 200) {
          All_Challenges();
        }
      });
  };

  const EditData = (id) => {
    if (id === null) {
      reset({
        challengename_english: "",
        challengename_arabic: "",
        challenge_from_date: "",
        challenge_to_date: "",
        challenge_description_arabic: "",
        challenge_description_english: "",
        no_of_steps_to_complete: "",
        challenge_status: "InActive",
      });
      setImage({ preview: null, raw: "" });
      setedit_id("");
      handleShow();
    } else {
      axios
        .get(`${BaseURL}/challenge_details/fetch_all_challenges/`, {
          params: {
            id: id,
          },
        })
        .then((res) => {
          // Update_Statuses();
          console.log("HERE");
          console.log(res.data);

          if (res.data.length != 0) {
            var data = res.data[0];
            reset({
              challengename_english: data.challenge_name_english,
              challengename_arabic: data.challenge_name_arabic,
              challenge_from_date: data.challenge_from_date,
              challenge_to_date: data.challenge_to_date,
              challenge_description_arabic: data.challenge_description_arabic,
              challenge_description_english: data.challenge_description_english,
              no_of_steps_to_complete: data.no_of_steps_to_complete,
              challenge_status: data.challenge_status,
            });
            setImage({ raw: "", preview: data.challenge_image });
            setedit_id(data.id);
            handleShow();
          }

          // if (res.data.status === 200) {
          //     console.log(res.data)
          //   var data = res.data.message[0]

          //   reset({

          //     "challengename_english" :  data.challengename_english,
          //     "challengename_arabic" : data.challengename_arabic,
          //     "programme_price" : data.programme_price,
          //     // "challenge_image" : data.challenge_image,
          //     "programme_status" : data.programme_status,
          //     "promocode" : data.programme_promocode,
          //     "fromdate" : data.promocode_from_date,
          //     "todate" : data.promocode_to_date,
          //     "onetimeuse" : data.is_onetimeuse,
          //   })
          //   setedit_id(data.id)
          //   handleShow()
          // }
        });
    }
  };

  const Create_Challenge = (data) => {
    setloading(true);

    if (image.preview === "") {
      setalert({
        open: true,
        msg: "Please provide a Challenge image",
        severity: "error",
      });
      setloading(false);
    } else {
      let form_data = new FormData();

      console.log(authtoken.access);

      form_data.append("challenge_name_english", data.challengename_english);
      form_data.append("challenge_name_arabic", data.challengename_arabic);
      form_data.append("challenge_from_date", data.challenge_from_date);
      form_data.append(
        "challenge_description_arabic",
        data.challenge_description_arabic
      );
      form_data.append(
        "challenge_description_english",
        data.challenge_description_english
      );
      form_data.append("challenge_to_date", data.challenge_to_date);
      // form_data.append("no_of_steps_to_complete",data.no_of_steps_to_complete)
      form_data.append("challenge_status", data.challenge_status);
      form_data.append("id", edit_id);
      form_data.append("action", "Add/Edit Challenge");

      if (image.raw != "") {
        form_data.append("challenge_image", image.raw);
      }

      axios
        .post(`${BaseURL}/challenge_details/admin_challenges/`, form_data, {
          headers: { Authorization: `Bearer ${authtoken.access}` },
        })
        .then((res) => {
          setloading(false);
          // Update_Statuses();
          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            handleClose();
            setImage({ preview: null, raw: "" });
            All_Challenges();
          } else {
            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        })
        .catch((error) => {
          setloading(false);
          setalert({
            open: true,
            msg: "Something Went Wrong",
            severity: "error",
          });
        });
    }
  };

  //   const StatusChange = (id) => {
  //       var data = {
  //         'id' : id,
  //         'action' : 'StatusChange'
  //       }
  //       axios.post(`${BaseURL}/programme_details/admin_programmes/`,data,
  //       { headers: {"Authorization" : `Bearer ${authtoken.access}`} }
  //     ).then((res) => {
  //       if (res.data.status === 200) {
  //           setalert({open:true,msg:res.data.message,severity:"success"})
  //           All_Challenges()
  //       }
  //       else{
  //         setalert({open:true,msg:res.data.message,severity:"error"})
  //       }
  //     })
  //     .catch((error)=>{
  //         setalert({open:true,msg:"Something Went Wrong",severity:"error"})
  //     })
  //   }

  const Delete_Challange = (id, programmename) => {
    if (window.confirm(`Do You Want to Delete ${programmename}`)) {
      var data = {
        id: id,
        action: "DeleteChallenge",
      };
      axios
        .post(`${BaseURL}/challenge_details/admin_challenges/`, data, {
          headers: { Authorization: `Bearer ${authtoken.access}` },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            All_Challenges();
          } else {
            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        })
        .catch((error) => {
          setalert({
            open: true,
            msg: "Something Went Wrong",
            severity: "error",
          });
        });
    }
  };

  const DeleteImage = () => {
    if (window.confirm("Do You Want To Delete This Image")) {
      var data = {
        action: "Delete Image",
        pgm_id: edit_id,
      };

      axios
        .post(`${BaseURL}/challenge_details/admin_challenges/`, data, {
          headers: { Authorization: `Bearer ${authtoken.access}` },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            All_Challenges();
            setImage({ raw: "", preview: null });
          } else {
            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        })
        .catch((error) => {
          setalert({
            open: true,
            msg: "Somethingg Went Wrong",
            severity: "error",
          });
        });
    }
  };

  const Fetch_Promocodes = (id) => {
    var data = {
      pgm_id: id,
      action: "Fetch_Promocodes",
    };

    axios
      .post(`${BaseURL}/challenge_details/admin_challenges/`, data, {
        headers: { Authorization: `Bearer ${authtoken.access}` },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setpromocodes(res.data.message);
          setpromocode_modal(true);
          setchallenge_id(id);
        }
      });
  };

  const Single_Promo_Edit = (id) => {
    var data = {
      pgm_id: challenge_id,
      id: id,
      action: "Fetch_Promocodes",
    };

    axios
      .post(`${BaseURL}/challenge_details/admin_challenges/`, data, {
        headers: { Authorization: `Bearer ${authtoken.access}` },
      })
      .then((res) => {
        if (res.data.status === 200) {
          if (res.data.message.length != 0) {
            var data = res.data.message[0];
            setpromocode_id(data.id);
            setpromo_code(data.challenge_promocode);
            setfrom_date(data.promocode_from_date);
            setto_date(data.promocode_to_date);
            setdiscount_percentage(data.discount_percentage);
            setonetime_use(data.is_onetimeuse);
          }
        }
      });
  };

  const Add_Promo_Code = () => {
    if (
      challenge_id === "" ||
      from_date === "" ||
      to_date === "" ||
      discount_percentage === ""
    ) {
      setalert({
        open: true,
        msg: "Please Fill Required Fields",
        severity: "error",
      });
    } else if (Date.parse(to_date) < Date.parse(from_date)) {
      setalert({
        open: true,
        msg: "To Date is Before Start Date",
        severity: "error",
      });
    } else {
      setpromo_loading(true);
      var data = {
        challenge_id: challenge_id,
        challenge_promocode: promo_code,
        promocode_from_date: from_date,
        promocode_to_date: to_date,
        is_onetimeuse: onetime_use,
        discount_percentage: discount_percentage,
        id: promocode_id,
        action: "Add/Edit Promocode",
      };

      axios
        .post(`${BaseURL}/challenge_details/admin_challenges/`, data, {
          headers: { Authorization: `Bearer ${authtoken.access}` },
        })
        .then((res) => {
          setpromo_loading(false);

          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            setpromocodes(res.data.datas);
            Clear_Promo_Details();
          } else {
            setpromo_loading(false);

            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        })
        .catch((error) => {
          setpromo_loading(false);
          setalert({
            open: true,
            msg: "Somethingg Went Wrong",
            severity: "error",
          });
        });
    }
  };

  const Delete_Promocode = (id) => {
    if (window.confirm("Do You Want To Delete This Promocode ?")) {
      var data = {
        id: id,
        action: "Delete Promocode",
      };

      axios
        .post(`${BaseURL}/challenge_details/admin_challenges/`, data, {
          headers: {
            Authorization: `Bearer ${authtoken.access}`,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            setpromocodes(res.data.datas);
          } else {
            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        })
        .catch((error) => {
          setalert({
            open: true,
            msg: "Somethingg Went Wrong",
            severity: "error",
          });
        });
    }
  };

  const Clear_Promo_Details = () => {
    setpromocode_id("");
    setpromo_code("");
    setfrom_date("");
    setto_date("");
    setdiscount_percentage("");
    setonetime_use(false);
  };

  const HandleClose_Promo = () => {
    setpromocode_id("");
    setpromo_code("");
    setfrom_date("");
    setto_date("");
    setdiscount_percentage("");
    setonetime_use(false);
    setchallenge_id("");
    setpromocode_modal(false);
    setpromocodes([]);
  };

  const datediff = (first, second) => {
    return Math.round((first - second) / (1000 * 60 * 60 * 24));
  };

  const parseDate = (str) => {
    var mdy = str.split("/");
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  };

  const Get_Today = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "-" + dd + "-" + yyyy;

    return today;
  };

  return (
    <div id="wrapper">
      {/* <Header />
      <Sidebar /> */}
      <div className="content-page">
        <div className="content  mt-4">
          {/* Start Content*/}
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-12">
                <div className="col-xl-12 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mt-0 mb-3">
                        <i className="mdi mdi-filter-outline" />
                        Filter{" "}
                      </h4>
                      <hr />
                      <div className="row">
                        <div className="col-md-3 col-sm-12">
                          <label
                            htmlFor="example-palaceholder"
                            className="form-label"
                          >
                            Name :
                          </label>
                          <input
                            type="text"
                            id="example-palaceholder"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-6 col-sm-12"
                          style={{ textAlign: "right" }}
                        ></div>
                        <div
                          className="col-md-6 col-sm-12 searchbtndiv"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            type="button"
                            className="btn btn-danger waves-effect waves-light"
                            style={{ marginRight: "5px" }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <h4 className="mt-0 header-title mb-2">Challenges</h4>
                      </div>
                      <div className="col-6" style={{ textAlign: "right" }}>
                        <button
                          type="button"
                          className="btn btn-info btn-xs waves-effect waves-light"
                          onClick={() => EditData(null)}
                        >
                          {" "}
                          Create challenge
                        </button>
                      </div>
                    </div>

                    <div className="table-responsive mt-2">
                      <table className="table table-striped table-bordered mb-0">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }}>#</th>
                            <th style={{ textAlign: "center" }}>Name</th>
                            <th style={{ textAlign: "center" }}>Image</th>
                            <th style={{ textAlign: "center" }}>Date</th>
                            {/* <th style={{ textAlign: "center" }}>Promocode</th> */}
                            <th style={{ textAlign: "center" }}>
                              Created Date
                            </th>
                            <th style={{ textAlign: "center" }}>Promocode</th>
                            <th style={{ textAlign: "center" }}>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {all_challenges.length === 0 ? (
                            <tr>
                              <td colSpan={8} style={{ textAlign: "center" }}>
                                {" "}
                                No Challenge Found
                              </td>
                            </tr>
                          ) : (
                            <>
                              {all_challenges.map((value, key) => (
                                <tr key={key}>
                                  <th scope="row">{key + 1}</th>
                                  <td>
                                    <b>Eng :</b> {value.challenge_name_english}
                                    <br />
                                    <b>Arab :</b>
                                    {value.challenge_name_arabic}
                                    <br />
                                    {/* <b>No Of Steps :</b>
                                    {value.no_of_steps_to_complete} */}
                                  </td>

                                  <td style={{ display: "flex" }}>
                                    <img
                                      src={value.challenge_image}
                                      style={{
                                        height: "100px",
                                        width: "80px",
                                        objectFit: "contain",
                                      }}
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    <b>From Data : &nbsp;&nbsp;</b>{" "}
                                    {moment(value.challenge_from_date).format(
                                      "MM/DD/YYYY"
                                    )}
                                    <br />
                                    <b>
                                      To Date &nbsp;&nbsp;&nbsp;&nbsp; :
                                      &nbsp;&nbsp;
                                    </b>
                                    {moment(value.challenge_to_date).format(
                                      "MM/DD/YYYY"
                                    )}
                                    <br />
                                    <b>
                                      Duration&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;
                                    </b>
                                    {datediff(
                                      parseDate(
                                        `${moment(
                                          value.challenge_to_date
                                        ).format("MM/DD/YYYY")}`
                                      ),
                                      parseDate(
                                        `${moment(
                                          value.challenge_from_date
                                        ).format("MM/DD/YYYY")}`
                                      )
                                    )}{" "}
                                    Days
                                    <br />
                                    <b>Activated&nbsp;&nbsp; : &nbsp;&nbsp;</b>
                                    {/* {datediff(
                                      parseDate(
                                        `${moment(new Date()).format(
                                          "MM/DD/YYYY"
                                        )}`
                                      ),
                                      parseDate(
                                        `${moment(
                                          value.challenge_from_date
                                        ).format("MM/DD/YYYY")}`
                                      )
                                    )}{" "}
                                    Days Before */}
                                    {Math.abs(
                                      datediff(
                                        parseDate(
                                          `${moment(new Date()).format(
                                            "MM/DD/YYYY"
                                          )}`
                                        ),
                                        parseDate(
                                          `${moment(
                                            value.challenge_from_date
                                          ).format("MM/DD/YYYY")}`
                                        )
                                      )
                                    )}{" "}
                                    Days Before
                                    <br />
                                    <b>Remaining : &nbsp;&nbsp;</b>
                                    {/* { datediff(parseDate(`${moment(value.challenge_to_date).format(
                                      "MM/DD/YYYY"
                                    )}`),parseDate(`${moment(new Date()).format(
                                      "MM/DD/YYYY"
                                    )}`)) }  Days Before */}
                                    <b>
                                      {`${
                                        datediff(
                                          parseDate(
                                            `${moment(
                                              value.challenge_to_date
                                            ).format("MM/DD/YYYY")}`
                                          ),
                                          parseDate(
                                            `${moment(new Date()).format(
                                              "MM/DD/YYYY"
                                            )}`
                                          )
                                        ) <= 0
                                          ? "Expired"
                                          : `${datediff(
                                              parseDate(
                                                `${moment(
                                                  value.challenge_to_date
                                                ).format("MM/DD/YYYY")}`
                                              ),
                                              parseDate(
                                                `${moment(new Date()).format(
                                                  "MM/DD/YYYY"
                                                )}`
                                              )
                                            )}`
                                      }`}
                                    </b>
                                  </td>

                                  <td>
                                    <b>Created :</b>
                                    {moment(value.created_date).format(
                                      "MM/DD/YYYY"
                                    )}
                                    <br />
                                    <b>Updated :</b>
                                    {moment(value.updated_date).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-xs waves-effect waves-light"
                                      onClick={() => Fetch_Promocodes(value.id)}
                                    >
                                      Promocode
                                    </button>
                                  </td>
                                  <td>
                                    {/* <p> 
                                      
                                      { datediff(parseDate(`${moment(value.challenge_to_date).format(
                                      "MM/DD/YYYY"
                                    )}`),parseDate(`${moment(new Date()).format(
                                      "MM/DD/YYYY"
                                    )}`)) } 
                                    
                                    
                                    </p> */}

                                    {value.challenge_status === "InActive" ? (
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-xs waves-effect waves-light mt-1"
                                      >
                                        In Activated
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-success btn-xs waves-effect waves-light mt-1"
                                      >
                                        Activated
                                      </button>
                                    )}

                                    <br />
                                    {/* <p>

                                    { datediff(parseDate(`${moment(value.challenge_from_date).format(
                                      "MM/DD/YYYY"
                                    )}`),parseDate(`${moment(new Date()).format(
                                      "MM/DD/YYYY"
                                    )}`)) === 0 || datediff(parseDate(`${moment(value.challenge_from_date).format(
                                      "MM/DD/YYYY"
                                    )}`),parseDate(`${moment(new Date()).format(
                                      "MM/DD/YYYY"
                                    )}`)) < 0 ? Change_Status(value.id,"Active") : Change_Status(value.id,"InActive") } 


                                    </p> */}
                                    <button
                                      type="button"
                                      className="btn btn-warning btn-xs waves-effect waves-light tooltips mt-1"
                                      onClick={() => EditData(value.id)}
                                    >
                                      Edit
                                    </button>
                                    <br />
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-xs waves-effect waves-light mt-1"
                                      onClick={() =>
                                        Delete_Challange(
                                          value.id,
                                          value.challenge_name_english
                                        )
                                      }
                                    >
                                      Delete
                                    </button>
                                    <br />
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        show={create_challenge_modal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            Create challenge
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Challenege Name ( English )
                </label>
                <input
                  type="text"
                  {...register("challengename_english")}
                  name="challengename_english"
                  placeholder="Challenge Name ( English )"
                  className={`form-control ${
                    errors.challengename_english ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.challengename_english?.message}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Challenege Name ( Arabic )
                </label>
                <input
                  type="text"
                  {...register("challengename_arabic")}
                  name="challengename_arabic"
                  placeholder=" Challenge Name ( Arabic )"
                  className={`form-control ${
                    errors.challengename_arabic ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.challengename_arabic?.message}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Challenege From Date
                </label>
                <input
                  type="date"
                  {...register("challenge_from_date")}
                  name="challenge_from_date"
                  className={`form-control ${
                    errors.challenge_from_date ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.challenge_from_date?.message}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Challenege To date
                </label>
                <input
                  type="date"
                  {...register("challenge_to_date")}
                  name="challenge_to_date"
                  className={`form-control ${
                    errors.challenge_to_date ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.challenge_to_date?.message}
                </div>
              </div>
            </div>

            {/* <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Number of Steps To Complete
                </label>
                <input
                  type="text"
                  {...register("no_of_steps_to_complete")}
                  name="no_of_steps_to_complete"
                  className={`form-control ${
                    errors.no_of_steps_to_complete ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.no_of_steps_to_complete?.message}
                </div>
              </div>
            </div> */}

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Challenge Status
                </label>

                <select
                  name="challenge_status"
                  {...register("challenge_status")}
                  className={`form-control ${
                    errors.challenge_status ? "is-invalid" : ""
                  }`}
                >
                  <option hidden value="">
                    {" "}
                    -- Choose Status --{" "}
                  </option>
                  <option value="Active">Active</option>
                  <option value="InActive">In Active</option>
                </select>
                <div className="invalid-feedback">
                  {errors.challenge_status?.message}
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Challenge Description ( English )
                </label>
                <textarea
                  rows={5}
                  {...register("challenge_description_english")}
                  name="challenge_description_english"
                  className={`form-control ${
                    errors.challenge_description_english ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.challenge_description_english?.message}
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Challenge Description ( Arabic )
                </label>
                <textarea
                  rows={5}
                  {...register("challenge_description_arabic")}
                  name="challenge_description_arabic"
                  className={`form-control ${
                    errors.challenge_description_arabic ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.challenge_description_arabic?.message}
                </div>
              </div>
            </div>

            <div className="col-6" style={{ textAlign: "center" }}>
              <label htmlFor="upload-button" style={{ textAlign: "center" }}>
                {image.preview != null ? (
                  <>
                    <img
                      src={image.preview}
                      width="150px"
                      height="200px"
                      style={{ objectFit: "contain" }}
                    />{" "}
                    <br />
                    {image.raw === "" ? (
                      <button
                        type="button"
                        className="btn btn-danger btn-xs waves-effect waves-light mt-1 mb-2"
                        onClick={DeleteImage}
                      >
                        Remove Photo
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <span className="fa-stack fa-2x mt-3 mb-2">
                      <i className="fas fa-sharp fa-solid fa-upload"></i>
                    </span>
                    <h5 className="text-center">Challenge Image</h5>
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="modal-footer">
            {loading ? (
              <button
                type="button"
                className="btn btn-info waves-effect waves-light"
                disabled={true}
              >
                Loading ...
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-secondary waves-effect"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-info waves-effect waves-light"
                  onClick={handleSubmit(Create_Challenge)}
                >
                  Save changes
                </button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={promocode_modal}
        onHide={HandleClose_Promo}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            Create Promocode
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive mt-2">
            <table className="table table-striped table-bordered mb-0">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>#</th>
                  <th style={{ textAlign: "center" }}>Details</th>
                  <th style={{ textAlign: "center" }}>Date</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>

              <tbody>
                {promocodes.length === 0 ? (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      {" "}
                      No Promocodes
                    </td>
                  </tr>
                ) : (
                  <>
                    {promocodes.map((value, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>
                          Code : {value.challenge_promocode} <br />
                          From :{" "}
                          {moment(value.promocode_from_date).format(
                            "MM/DD/YYYY"
                          )}{" "}
                          <br />
                          To :{" "}
                          {moment(value.promocode_to_date).format(
                            "MM/DD/YYYY"
                          )}{" "}
                          <br />
                          Percentage : {value.discount_percentage} % <br />
                          Use :{" "}
                          {value.is_onetimeuse === true ? (
                            <b>Single Use</b>
                          ) : (
                            <b>Multiple Use</b>
                          )}{" "}
                          <br />
                        </td>
                        <td>
                          <b>Created :</b>
                          {moment(value.created_date).format("MM/DD/YYYY")}
                          <br />
                          <b>Updated :</b>
                          {moment(value.updated_date).format("MM/DD/YYYY")}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-warning btn-xs waves-effect waves-light tooltips"
                            onClick={() => Single_Promo_Edit(value.id)}
                          >
                            Edit
                          </button>{" "}
                          <br />
                          <button
                            type="button"
                            className="btn btn-danger mt-2 btn-xs waves-effect waves-light tooltips"
                            onClick={() => Delete_Promocode(value.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                    <td>1</td>
                    <td>
                        <b>Code : </b> ghgfdgshhhhhhhhhhhhhhhhhhhhhhhhhhhhhfl <br/>
                    <b>From : </b> 12/ 34/ 3456 <br/>
                    <b>To : </b> 23 / 45 / 567 <br/>
                    <b>One time </b> <br/>
                    <b> Discount Perct</b> 30 %
                    </td>
                    <td>
                        12/ 34/ 3456 <br/>
                        12/ 34/ 3456
                    </td>
                    <td>
                    <button
                        type="button"
                        className="btn btn-danger btn-xs waves-effect waves-light mt-1"
                    >
                        Delete
                    </button> <br>
                    <button
                                      type="button"
                                      className="btn btn-danger btn-xs waves-effect waves-light mt-1"
                                    >
                                      Delete
                                    </button></br>
                    </td>

                    </tr> */}
                  </>
                )}
              </tbody>
            </table>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Promocode
                </label>
                <input
                  type="text"
                  value={promo_code}
                  onChange={(e) => setpromo_code(e.target.value)}
                  className="form-control"
                  name="promocode"
                  placeholder="Promocode"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  From Date
                </label>
                <input
                  type="date"
                  value={from_date}
                  onChange={(e) => setfrom_date(e.target.value)}
                  className="form-control"
                  name="promocode"
                  placeholder="Promocode"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  To Date
                </label>
                <input
                  type="date"
                  value={to_date}
                  onChange={(e) => setto_date(e.target.value)}
                  className="form-control"
                  name="promocode"
                  placeholder="Promocode"
                />
              </div>
            </div>

            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Discount %
                </label>
                <input
                  type="number"
                  value={discount_percentage}
                  onChange={(e) => setdiscount_percentage(e.target.value)}
                  className="form-control"
                  name="promocode"
                  placeholder="Discount Percentage"
                />
              </div>
            </div>

            <div className="col-2 mt-4">
              <div className="mb-3">
                <div className="form-check">
                  <label className="form-check-label" htmlFor="checkbox-signin">
                    One Time Use
                  </label>
                  <input
                    type="checkbox"
                    checked={onetime_use === true ? true : false}
                    onChange={() => setonetime_use(!onetime_use)}
                    name="onetimeuse"
                    placeholder="Username"
                    className="form-check-input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2 mb-3">
            <div className="col-8"></div>
            <div className="col-2" style={{ textAlign: "right" }}>
              {promo_loading ? (
                ""
              ) : (
                <button
                  type="button"
                  className="btn btn-warning waves-effect waves-light"
                  onClick={Clear_Promo_Details}
                >
                  Clear
                </button>
              )}
            </div>
            <div className="col-2">
              {promo_loading ? (
                <button
                  type="button"
                  className="btn btn-info waves-effect waves-light"
                  disabled={true}
                >
                  Loading ...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-info waves-effect waves-light"
                  onClick={Add_Promo_Code}
                >
                  Save Code
                </button>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary waves-effect"
              onClick={HandleClose_Promo}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Snackbar
        open={alert.open}
        TransitionComponent={Collapse}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={AlertClose}
      >
        <Alert onClose={AlertClose} severity={alert.severity}>
          {alert.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ChallengePage;
