import { createContext, useEffect, useState} from "react";
import jwt_decode from 'jwt-decode'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../Constants/ConstantURL";


export const SimpleContext = createContext()

const AuthContext = ({children}) => {
    let navigate = useNavigate()


    const [is_sidebar, setis_sidebar] = useState(false)
    
    const [authtoken, setauthtoken] = useState(()=>localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    const [user, setUser] = useState(()=>localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)
    const [loading, setloading] = useState()

    const Logout = (msg) => {
        setauthtoken(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        return navigate('/login',{ state : { msg : msg}})
    }


    const UpdateToken = () => {
        var data = {
            'refresh' : authtoken.refresh
        }
        axios.post(`${BaseURL}/user_details/get_refresh_token/`,data)
        .then((res) => {
            console.log("Update Token")
           
            setauthtoken(res.data)
            setUser(jwt_decode(res.data.access))
            localStorage.setItem('authTokens',JSON.stringify(res.data))
        })
        .catch((err) => {
            Logout()
        })
    }

    useEffect(() => {
        
        let interval = setInterval (() => {
            if (authtoken) {
                UpdateToken()
            }

        },  60 * 60 * 1000 );
        return ()=> clearInterval(interval)

    }, [authtoken,loading])
    


    return <SimpleContext.Provider value={{

        authtoken,setauthtoken,user,setUser,Logout,is_sidebar,setis_sidebar

    }}>{children}</SimpleContext.Provider>
}

export default AuthContext