import axios from 'axios'
import React, { useContext, useEffect , useState  } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import { BaseURL } from '../Constants/ConstantURL'
import { SimpleContext } from '../Context/AuthContext'



function Dashboard() {

    const {authtoken , setis_sidebar} = useContext(SimpleContext)
    const {user } = useContext(SimpleContext)

    const [datas, setdatas] = useState("")

    let navigate = useNavigate()

    let { state } = useLocation()

    useEffect(() => {
      setis_sidebar(false)
      Get_Datas()
      const timer = setTimeout(() => {
        navigate('/', { replace: true });
      }, 3000);
      return () => clearTimeout(timer);
    }, []);



    const Get_Datas  = async () => {
      await axios.get(`${BaseURL}/user_details/dashboard_datas/`)
      .then((res) => {
        if (res.data.status === 200)
        {
          setdatas(res.data)
        }
      })
    }
    
 
  return (
    <div id="wrapper" >
 
 <div className="content-page">
   <div className="content mt-4">



    { 
    state === null ? '' :    
    <div class="alert alert-success bg-success text-white border-0" role="alert" style={{ textAlign:"center"}}>
        <strong>{ state.msg }</strong>
    </div>
    }

    
   
     <div className="container-fluid">

       <div className="row mt-2">
        
         {/* <div className="col-xl-3 col-md-6">
           <div className="card" style={{ padding:"10px"}}>
             <div className="card-body">
           
               <h4 className="header-title mt-0 mb-3">Total Admins</h4>
               <div className="widget-box-2">
                 <div className="widget-detail-2 text-end">
                   <span className="badge bg-success rounded-pill float-start mt-3">32% <i className="mdi mdi-trending-up" /> </span>
                   <h2 className="fw-normal mb-1"> {datas.admin_count} </h2>
                   <p className="text-muted mb-3">Revenue today</p>
                 </div>
                 <div className="progress progress-bar-alt-success progress-sm">
                   <div className="progress-bar bg-success" role="progressbar" aria-valuenow={77} aria-valuemin={0} aria-valuemax={100} style={{width: '77%'}}>
                     <span className="visually-hidden">77% Complete</span>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div> */}


          
         <div className="col-xl-3 col-md-6">
           <div className="card" style={{ padding:"10px"}}>
             <div className="card-body">
           
               <h4 className="header-title mt-0 mb-3">Total Programmes</h4>
               <div className="widget-box-2">
                 <div className="widget-detail-2 text-end">
                   {/* <span className="badge bg-success rounded-pill float-start mt-3">32% <i className="mdi mdi-trending-up" /> </span> */}
                   <h2 className="fw-normal mb-1"> {datas.total_programmes} </h2>
                   {/* <p className="text-muted mb-3">Revenue today</p> */}
                 </div>
                 {/* <div className="progress progress-bar-alt-success progress-sm">
                   <div className="progress-bar bg-success" role="progressbar" aria-valuenow={77} aria-valuemin={0} aria-valuemax={100} style={{width: '77%'}}>
                     <span className="visually-hidden">77% Complete</span>
                   </div>
                 </div> */}
               </div>
             </div>
           </div>
         </div>



          
         <div className="col-xl-3 col-md-6">
           <div className="card" style={{ padding:"10px"}}>
             <div className="card-body">
           
               <h4 className="header-title mt-0 mb-3">Total Challenges</h4>
               <div className="widget-box-2">
                 <div className="widget-detail-2 text-end">
                   {/* <span className="badge bg-success rounded-pill float-start mt-3">32% <i className="mdi mdi-trending-up" /> </span> */}
                   <h2 className="fw-normal mb-1"> {datas.total_challenges} </h2>
                   {/* <p className="text-muted mb-3">Revenue today</p> */}
                 </div>
                 {/* <div className="progress progress-bar-alt-success progress-sm">
                   <div className="progress-bar bg-success" role="progressbar" aria-valuenow={77} aria-valuemin={0} aria-valuemax={100} style={{width: '77%'}}>
                     <span className="visually-hidden">77% Complete</span>
                   </div>
                 </div> */}
               </div>
             </div>
           </div>
         </div>


          
         <div className="col-xl-3 col-md-6">
           <div className="card" style={{ padding:"10px"}}>
             <div className="card-body">
           
               <h4 className="header-title mt-0 mb-3">Active Programmes</h4>
               <div className="widget-box-2">
                 <div className="widget-detail-2 text-end">
                   {/* <span className="badge bg-success rounded-pill float-start mt-3">32% <i className="mdi mdi-trending-up" /> </span> */}
                   <h2 className="fw-normal mb-1"> {datas.active_programmes} </h2>
                   {/* <p className="text-muted mb-3">Revenue today</p> */}
                 </div>
                 {/* <div className="progress progress-bar-alt-success progress-sm">
                   <div className="progress-bar bg-success" role="progressbar" aria-valuenow={77} aria-valuemin={0} aria-valuemax={100} style={{width: '77%'}}>
                     <span className="visually-hidden">77% Complete</span>
                   </div>
                 </div> */}
               </div>
             </div>
           </div>
         </div>



          
         <div className="col-xl-3 col-md-6">
           <div className="card" style={{ padding:"10px"}}>
             <div className="card-body">
           
               <h4 className="header-title mt-0 mb-3">Active Challenges</h4>
               <div className="widget-box-2">
                 <div className="widget-detail-2 text-end">
                   {/* <span className="badge bg-success rounded-pill float-start mt-3">32% <i className="mdi mdi-trending-up" /> </span> */}
                   <h2 className="fw-normal mb-1"> {datas.active_challenges} </h2>
                   {/* <p className="text-muted mb-3">Revenue today</p> */}
                 </div>
                 {/* <div className="progress progress-bar-alt-success progress-sm">
                   <div className="progress-bar bg-success" role="progressbar" aria-valuenow={77} aria-valuemin={0} aria-valuemax={100} style={{width: '77%'}}>
                     <span className="visually-hidden">77% Complete</span>
                   </div>
                 </div> */}
               </div>
             </div>
           </div>
         </div>







       </div>
      
     
      
       
     </div> 
   </div> 

   <Footer />
 </div>
 </div>
  )
}

export default Dashboard