import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { SimpleContext } from '../Context/AuthContext'

function Sidebar() {

  const {user } = useContext(SimpleContext)
  const query = new URLSearchParams(window.location.search);

  const [is_margineneeded, setis_margineneeded] = useState(true)


  useEffect(() => {

  }, [])
  


  return (
    <div className="left-side-menu">
    <div className="h-100" data-simplebar>
      {/* User box */}
      <div className="user-box text-center">
        <img src="assets/images/users/user-1.jpg" alt="user-img" title="Mat Helme" className="rounded-circle img-thumbnail avatar-md" />
        <div className="dropdown">
          <a href="/" className="user-name dropdown-toggle h5 mt-2 mb-1 d-block" data-bs-toggle="dropdown" aria-expanded="false">Nowak Helme</a>
          <div className="dropdown-menu user-pro-dropdown">
            {/* item*/}
            <a href="/" className="dropdown-item notify-item">
              <i className="fe-user me-1" />
              <span>My Account</span>
            </a>
            {/* item*/}
            <a href="/" className="dropdown-item notify-item">
              <i className="fe-settings me-1" />
              <span>Settings</span>
            </a>
            {/* item*/}
            <a href="/" className="dropdown-item notify-item">
              <i className="fe-lock me-1" />
              <span>Lock Screen</span>
            </a>
            {/* item*/}
            <a href="/" className="dropdown-item notify-item">
              <i className="fe-log-out me-1" />
              <span>Logout</span>
            </a>
          </div>
        </div>
        <p className="text-muted left-user-info">Admin Head</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="/" className="text-muted left-user-info">
              <i className="mdi mdi-cog" />
            </a>
          </li>
          <li className="list-inline-item">
            <a href="/">
              <i className="mdi mdi-power" />
            </a>
          </li>
        </ul>
      </div>
      {/*- Sidemenu */}
      <div id="sidebar-menu">
        <ul id="side-menu">
          <li className="menu-title">Navigation</li>
          <li>
            <Link to="/">
              <i className="mdi mdi-view-dashboard-outline" />
              {/* <span className="badge bg-success rounded-pill float-end">9+</span> */}
              <span> Dashboard </span>
            </Link>
          </li>
          <li className="menu-title mt-2">Templates</li>

          {/* {user.is_superuser === true ? 
          <li>
            <Link to="/create-admin">
              <i className="mdi mdi-account-multiple" />
              <span> Admin </span>
            </Link>
          </li> : ''} */}
          <li>
            <Link to="/create-programmes">
              <i className="mdi mdi-book-open-variant" />
              <span> Programmes </span>
            </Link>
          </li>

          <li>
            <Link to="/create-challenge">
              <i className="mdi mdi-calendar-blank-outline" />
              <span> RS Challenges </span>
            </Link>
          </li>


          <li>
            <Link to="/joined-users">
              <i className="mdi mdi-account-multiple" />
              <span> Joined Users </span>
            </Link>
          </li>


          {/* <li>
            <Link to="/enrolled-challenges">
              <i className="mdi mdi-calendar-blank-outline" />
              <span> Enrolled Challenges </span>
            </Link>
          </li>

          <li>
            <Link to="/enrolled-programmes">
              <i className="mdi mdi-calendar-blank-outline" />
              <span> Enrolled Programmes </span>
            </Link>
          </li> */}


          
     






          {/* <li>
            <a href="#email" data-bs-toggle="collapse">
              <i className="mdi mdi-email-outline" />
              <span> Product Type </span>
              <span className="menu-arrow" />
            </a>
            <div className="collapse" id="email">
              <ul className="nav-second-level">
                <li>
                  <a href="email-inbox.html">Inbox</a>
                </li>
                <li>
                  <a href="email-templates.html">Email Templates</a>
                </li>
              </ul>
            </div>
          </li> */}

          
       


       
        </ul>
      </div>
      {/* End Sidebar */}
      <div className="clearfix" />
    </div>
    {/* Sidebar -left */}
  </div>
  )
}

export default Sidebar