import React, { useContext, useEffect, useState } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Collapse } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Modal } from "react-bootstrap";
import moment from "moment";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SimpleContext } from "../Context/AuthContext";
import { BaseURL } from "../Constants/ConstantURL";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} variant="filled" {...props} />;
});

function ProgrammePage() {
  let navigate = useNavigate();

  const [image, setImage] = useState({ preview: null, raw: "" });

  const { authtoken, setis_sidebar } = useContext(SimpleContext);
  const [loading, setloading] = useState(false);
  const [promo_loading, setpromo_loading] = useState(false);

  const [alert, setalert] = useState({
    open: false,
    msg: "",
    severity: "",
  });

  const [all_programme, setall_programme] = useState([]);
  const [create_programme_modal, setcreate_programme_modal] = useState(false);
  const [promocode_modal, setpromocode_modal] = useState(false);
  const [promocodes, setpromocodes] = useState([]);

  const [edit_id, setedit_id] = useState("");

  const [promocode_id, setpromocode_id] = useState("");
  const [promo_code, setpromo_code] = useState("");
  const [programme_id, setprogramme_id] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [discount_percentage, setdiscount_percentage] = useState("");
  const [onetime_use, setonetime_use] = useState(false);

  const validationSchema = Yup.object().shape({
    programmename_english: Yup.string().required(
      "Programme Name English Is Required"
    ),
    programmename_arabic: Yup.string().required(
      "Programme Name Arabic Is Required"
    ),

    programme_details_english: Yup.string().required(
      "Programme Description English Is Required"
    ),
    programme_details_arabic: Yup.string().required(
      "Programme Description Arabic Is Required"
    ),
    programme_price: Yup.string(),
    programme_image: Yup.mixed(),
    programme_status: Yup.string().required("Programme Status"),
    // promocode: Yup.string(),
    // fromdate: Yup.string().nullable().notRequired(),
    // todate: Yup.string().nullable().notRequired(),
    // onetimeuse: Yup.bool(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const handleClose = () => {
    setcreate_programme_modal(false);
    reset();
    setedit_id("");
    setpromocode_id("");
    setprogramme_id("");
  };
  const handleShow = () => {
    setcreate_programme_modal(true);
  };

  const AlertClose = (event, reason) => {
    setalert({ open: false, message: "", severity: "" });
  };

  useEffect(() => {
    setis_sidebar(false);
    All_Programmes();
  }, []);

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      console.log(e.target.files[0]);
    }
  };

  const All_Programmes = () => {
    axios
      .get(`${BaseURL}/programme_details/fetch_all_programme/`)
      .then((res) => {
        console.log(res.data);
        setall_programme(res.data);
      });
  };

  const EditData = (id) => {
    if (id === null) {
      reset({
        programmename_english: "",
        programmename_arabic: "",
        programme_price: "",
        programme_status: "",
        programme_details_english: "",
        programme_details_arabic: "",
      });
      setImage({ preview: null, raw: "" });
      setedit_id("");
      handleShow();
    } else {
      axios
        .get(`${BaseURL}/programme_details/fetch_all_programme/`, {
          params: {
            id: id,
          },
        })
        .then((res) => {
          console.log("HERE");
          console.log(res.data);

          if (res.data.length != 0) {
            var data = res.data[0];
            reset({
              programmename_english: data.programmename_english,
              programmename_arabic: data.programmename_arabic,
              programme_price: data.programme_price,
              programme_status: data.programme_status,
              programme_details_english: data.programme_details_english,
              programme_details_arabic: data.programme_details_arabic,
            });
            setImage({ raw: "", preview: data.programme_image });
            setedit_id(data.id);
            handleShow();
          }

          if (res.data.status === 200) {
            console.log(res.data);
            var data = res.data.message[0];

            reset({
              programmename_english: data.programmename_english,
              programmename_arabic: data.programmename_arabic,
              programme_price: data.programme_price,
              // "programme_image" : data.programme_image,
              programme_status: data.programme_status,
              promocode: data.programme_promocode,
              fromdate: data.promocode_from_date,
              todate: data.promocode_to_date,
              onetimeuse: data.is_onetimeuse,
            });
            setedit_id(data.id);
            handleShow();
          }
        });
    }
  };

  const Create_Programme = (data) => {
    if (image.preview === "") {
      setalert({
        open: true,
        msg: "Provide a Programme Image",
        severity: "error",
      });
    } else {
      setloading(true);
      let form_data = new FormData();

      form_data.append("programmename_english", data.programmename_english);
      form_data.append("programmename_arabic", data.programmename_arabic);
      form_data.append(
        "programme_details_english",
        data.programme_details_english
      );
      form_data.append(
        "programme_details_arabic",
        data.programme_details_arabic
      );
      form_data.append("programme_price", data.programme_price);
      form_data.append("programme_status", data.programme_status);
      form_data.append("id", edit_id);
      form_data.append("action", "Add/Edit Programme");

      if (image.raw != "") {
        form_data.append("programme_image", image.raw);
      }

      console.log(authtoken.access);

      axios
        .post(`${BaseURL}/programme_details/admin_programmes/`, form_data, {
          headers: { Authorization: `Bearer ${authtoken.access}` },
        })
        .then((res) => {
          setloading(false);
          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            handleClose();
            setImage({ preview: null, raw: "" });
            All_Programmes();
          } else {
            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        })
        .catch((error) => {
          setloading(false);
          setalert({
            open: true,
            msg: "Somethin Went Wrong",
            severity: "error",
          });
        });
    }
  };

  const StatusChange = (id) => {
    var data = {
      id: id,
      action: "StatusChange",
    };
    axios
      .post(`${BaseURL}/programme_details/admin_programmes/`, data, {
        headers: { Authorization: `Bearer ${authtoken.access}` },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setalert({ open: true, msg: res.data.message, severity: "success" });
          All_Programmes();
        } else {
          setalert({ open: true, msg: res.data.message, severity: "error" });
        }
      })
      .catch((error) => {
        setalert({ open: true, msg: "Somethin Went Wrong", severity: "error" });
      });
  };

  const Delete_Programme = (id, programmename) => {
    if (window.confirm(`Do You Want to Delete ${programmename}`)) {
      var data = {
        id: id,
        action: "DeleteProgramme",
      };
      axios
        .post(`${BaseURL}/programme_details/admin_programmes/`, data, {
          headers: { Authorization: `Bearer ${authtoken.access}` },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            All_Programmes();
          } else {
            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        })
        .catch((error) => {
          setalert({
            open: true,
            msg: "Somethin Went Wrong",
            severity: "error",
          });
        });
    }
  };

  const DeleteImage = () => {
    if (window.confirm("Do You Want To Delete This Image")) {
      var data = {
        action: "Delete Image",
        pgm_id: edit_id,
      };

      axios
        .post(`${BaseURL}/programme_details/admin_programmes/`, data, {
          headers: { Authorization: `Bearer ${authtoken.access}` },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            All_Programmes();
            setImage({ raw: "", preview: null });
          } else {
            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        })
        .catch((error) => {
          setalert({
            open: true,
            msg: "Something Went Wrong",
            severity: "error",
          });
        });
    }
  };

  const Fetch_Promocodes = (id) => {
    var data = {
      pgm_id: id,
      action: "Fetch_Promocodes",
    };

    axios
      .post(`${BaseURL}/programme_details/admin_programmes/`, data, {
        headers: { Authorization: `Bearer ${authtoken.access}` },
      })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data.message);
          setpromocodes(res.data.message);
          setpromocode_modal(true);
          setprogramme_id(id);
        }
      });
  };

  const Single_Promo_Edit = (id) => {
    var data = {
      pgm_id: programme_id,
      id: id,
      action: "Fetch_Promocodes",
    };

    axios
      .post(`${BaseURL}/programme_details/admin_programmes/`, data, {
        headers: { Authorization: `Bearer ${authtoken.access}` },
      })
      .then((res) => {
        if (res.data.status === 200) {
          // setpromocodes(res.data.message)
          // setpromocode_modal(true)
          // setprogramme_id(id)
          if (res.data.message.length != 0) {
            var data = res.data.message[0];
            setpromocode_id(data.id);
            setpromo_code(data.programme_promocode);
            setfrom_date(data.promocode_from_date);
            setto_date(data.promocode_to_date);
            setdiscount_percentage(data.discount_percentage);
            setonetime_use(data.is_onetimeuse);
          }
        }
      });
  };

  const Add_Promo_Code = () => {
    if (
      programme_id === "" ||
      from_date === "" ||
      to_date === "" ||
      discount_percentage === ""
    ) {
      setalert({
        open: true,
        msg: "Please Fill Required Fields",
        severity: "error",
      });
    } else if (Date.parse(to_date) < Date.parse(from_date)) {
      setalert({
        open: true,
        msg: "To Date is Before Start Date",
        severity: "error",
      });
    } else {
      setpromo_loading(true);
      var data = {
        programme_id: programme_id,
        programme_promocode: promo_code,
        promocode_from_date: from_date,
        promocode_to_date: to_date,
        is_onetimeuse: onetime_use,
        discount_percentage: discount_percentage,
        id: promocode_id,
        action: "Add/Edit Promocode",
      };

      axios
        .post(`${BaseURL}/programme_details/admin_programmes/`, data, {
          headers: { Authorization: `Bearer ${authtoken.access}` },
        })
        .then((res) => {
          setpromo_loading(false);
          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            setpromocodes(res.data.datas);
            Clear_Promo_Details();
          } else {
            setpromo_loading(false);
            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        });
    }
  };

  const Delete_Promocode = (id) => {
    if (window.confirm("Do You Want To Delete This Promocode ?")) {
      var data = {
        id: id,
        action: "Delete Promocode",
      };

      axios
        .post(`${BaseURL}/programme_details/admin_programmes/`, data, {
          headers: {
            Authorization: `Bearer ${authtoken.access}`,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setalert({
              open: true,
              msg: res.data.message,
              severity: "success",
            });
            setpromocodes(res.data.datas);
          } else {
            setalert({ open: true, msg: res.data.message, severity: "error" });
          }
        })
        .catch((error) => {
          setalert({
            open: true,
            msg: "Something Went Wrong",
            severity: "error",
          });
        });
    }
  };

  const Clear_Promo_Details = () => {
    setpromocode_id("");
    setpromo_code("");
    setfrom_date("");
    setto_date("");
    setdiscount_percentage("");
    setonetime_use(false);
  };

  const HandleClose_Promo = () => {
    setpromocode_id("");
    setpromo_code("");
    setfrom_date("");
    setto_date("");
    setdiscount_percentage("");
    setonetime_use(false);
    setprogramme_id("");
    setpromocode_modal(false);
    setpromocodes([]);
  };

  return (
    <div id="wrapper">
      {/* <Header />
      <Sidebar /> */}
      <div className="content-page">
        <div className="content  mt-4">
          {/* Start Content*/}
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-12">
                <div className="col-xl-12 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mt-0 mb-3">
                        <i className="mdi mdi-filter-outline" />
                        Filter{" "}
                      </h4>
                      <hr />
                      <div className="row">
                        <div className="col-md-3 col-sm-12">
                          <label
                            htmlFor="example-palaceholder"
                            className="form-label"
                          >
                            Name :
                          </label>
                          <input
                            type="text"
                            id="example-palaceholder"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-6 col-sm-12"
                          style={{ textAlign: "right" }}
                        ></div>
                        <div
                          className="col-md-6 col-sm-12 searchbtndiv"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            type="button"
                            className="btn btn-danger waves-effect waves-light"
                            style={{ marginRight: "5px" }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <h4 className="mt-0 header-title mb-2">Programmes</h4>
                      </div>
                      <div className="col-6" style={{ textAlign: "right" }}>
                        <button
                          type="button"
                          className="btn btn-info btn-xs waves-effect waves-light"
                          onClick={() => EditData(null)}
                        >
                          {" "}
                          Create Programme
                        </button>
                      </div>
                    </div>

                    <div className="table-responsive mt-2">
                      <table className="table table-striped table-bordered mb-0">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }}>#</th>
                            <th style={{ textAlign: "center" }}>Name</th>
                            <th style={{ textAlign: "center" }}>Price</th>
                            <th style={{ textAlign: "center" }}>Image</th>
                            {/* <th style={{ textAlign: "center" }}>Promocode</th> */}
                            <th style={{ textAlign: "center" }}>
                              Created Date
                            </th>
                            <th style={{ textAlign: "center" }}>Promocode</th>
                            <th style={{ textAlign: "center" }}>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {all_programme.length === 0 ? (
                            <tr>
                              <td colSpan={8} style={{ textAlign: "center" }}>
                                {" "}
                                No Programmes Found
                              </td>
                            </tr>
                          ) : (
                            <>
                              {all_programme.map((value, key) => (
                                <tr key={key}>
                                  <th scope="row">{key + 1}</th>
                                  <td>
                                    <b>Eng :</b> {value.programmename_english}
                                    <br />
                                    <b>Arab :</b>
                                    {value.programmename_arabic}
                                  </td>
                                  <td>
                                    {value.programme_price === "" ||
                                    value.programme_price === null ||
                                    value.programme_price === 0 ? (
                                      <b>Free</b>
                                    ) : (
                                      <>
                                        {value.programme_price} &nbsp;
                                        <b>USD</b>
                                      </>
                                    )}
                                    {/* {value.programme_price}
                                    <b>USD</b> */}
                                  </td>
                                  <td style={{ display: "flex" }}>
                                    <img
                                      src={value.programme_image}
                                      style={{
                                        height: "100px",
                                        width: "80px",
                                        objectFit: "contain",
                                      }}
                                      alt=""
                                    />
                                  </td>

                                  {/* <td>
                                    <b>Code :</b>
                                    {value.programme_promocode}
                                    <br />
                                    <b>From :</b>
                                    {value.promocode_from_date}
                                    <br />
                                    <b>To :</b>
                                    {value.promocode_to_date}
                                    <br />
                                    {value.programme_promocode === null ||
                                    value.programme_promocode === "" ? (
                                      ""
                                    ) : (
                                      <>
                                        {value.is_onetimeuse === true ? (
                                          <b>One time Use</b>
                                        ) : (
                                          <b>Multiple Use</b>
                                        )}
                                      </>
                                    )}
                                  </td> */}

                                  <td>
                                    <b>Created :</b>
                                    {moment(value.created_date).format(
                                      "MM/DD/YYYY"
                                    )}
                                    <br />
                                    <b>Updated :</b>
                                    {moment(value.updated_date).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {value.programme_price === "" ||
                                    value.programme_price === null ||
                                    value.programme_price === 0 ||
                                    value.programme_price === "0" ? (
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-xs waves-effect waves-light"
                                        disabled={true}
                                        style={{ cursor: "not-allowed" }}
                                      >
                                        Promocode
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-xs waves-effect waves-light"
                                        onClick={() =>
                                          Fetch_Promocodes(value.id)
                                        }
                                      >
                                        Promocode
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-warning btn-xs waves-effect waves-light tooltips"
                                      onClick={() => EditData(value.id)}
                                    >
                                      Edit
                                    </button>
                                    <br />
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-xs waves-effect waves-light mt-1"
                                      onClick={() =>
                                        Delete_Programme(
                                          value.id,
                                          value.programmename_english
                                        )
                                      }
                                    >
                                      Delete
                                    </button>
                                    <br />
                                    {value.programme_status === "Active" ? (
                                      <button
                                        type="button"
                                        className="btn btn-success btn-xs waves-effect waves-light mt-1"
                                        onClick={() => StatusChange(value.id)}
                                      >
                                        Active
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-xs waves-effect waves-light mt-1"
                                        onClick={() => StatusChange(value.id)}
                                      >
                                        Block
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        show={create_programme_modal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            Create Programme
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Name ( English )
                </label>
                <input
                  type="text"
                  {...register("programmename_english")}
                  name="programmename_english"
                  placeholder="Programme Name ( English )"
                  className={`form-control ${
                    errors.programmename_english ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.programmename_english?.message}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Name ( Arabic )
                </label>
                <input
                  type="text"
                  {...register("programmename_arabic")}
                  name="programmename_arabic"
                  placeholder=" Programme Name ( Arabic )"
                  className={`form-control ${
                    errors.programmename_arabic ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.programmename_arabic?.message}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Price
                </label>
                <input
                  type="text"
                  {...register("programme_price")}
                  name="programme_price"
                  placeholder="Programme Price"
                  className={`form-control ${
                    errors.programme_price ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.programme_price?.message}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Status
                </label>

                <select
                  name="programme_status"
                  {...register("programme_status")}
                  className={`form-control ${
                    errors.programme_status ? "is-invalid" : ""
                  }`}
                >
                  <option hidden value="">
                    {" "}
                    -- Choose Status --{" "}
                  </option>
                  <option value="Active">Active</option>
                  <option value="InActive">In Active</option>
                </select>
                <div className="invalid-feedback">
                  {errors.programme_status?.message}
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Description ( English )
                </label>
                <textarea
                  rows={5}
                  {...register("programme_details_english")}
                  name="programme_details_english"
                  className={`form-control ${
                    errors.programme_details_english ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.programme_details_english?.message}
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Description ( Arabic )
                </label>
                <textarea
                  rows={5}
                  {...register("programme_details_arabic")}
                  name="programme_details_arabic"
                  className={`form-control ${
                    errors.programme_details_arabic ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.programme_details_arabic?.message}
                </div>
              </div>
            </div>

            <div className="col-6" style={{ textAlign: "center" }}>
              <label htmlFor="upload-button" style={{ textAlign: "center" }}>
                {image.preview != null ? (
                  <>
                    <img
                      src={image.preview}
                      width="150px"
                      height="200px"
                      style={{ objectFit: "contain" }}
                    />{" "}
                    <br />
                    {image.raw === "" ? (
                      <button
                        type="button"
                        className="btn btn-danger btn-xs waves-effect waves-light mt-1 mb-2"
                        onClick={DeleteImage}
                      >
                        Remove Photo
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <span className="fa-stack fa-2x mt-3 mb-2">
                      <i className="fas fa-sharp fa-solid fa-upload"></i>
                    </span>
                    <h5 className="text-center">Programme Image</h5>
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Name ( English )
                </label>
                <input
                  type="text"
                  {...register("programmename_english")}
                  name="programmename_english"
                  placeholder="Programme Name ( English )"
                  className={`form-control ${
                    errors.programmename_english ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.programmename_english?.message}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Name ( Arabic )
                </label>
                <input
                  type="text"
                  {...register("programmename_arabic")}
                  name="programmename_arabic"
                  placeholder=" Programme Name ( Arabic )"
                  className={`form-control ${
                    errors.programmename_arabic ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.programmename_arabic?.message}
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Price
                </label>
                <input
                  type="text"
                  {...register("programme_price")}
                  name="programme_price"
                  placeholder="Programme Price"
                  className={`form-control ${
                    errors.programme_price ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.programme_price?.message}
                </div>
              </div>
            </div>


            <div className='col-6 mt-2' style={{ textAlign:"center"}}>
                <label htmlFor="upload-button"  style={{ textAlign:"center"}}>
                    {image.preview != null ? (
                        <>
                            <img src={image.preview}  width="150px" height="200px" style={{ objectFit:"contain" }} /> <br/>

                 
                            { image.raw === "" ? 
                            <button type="button" className="btn btn-danger btn-xs waves-effect waves-light mt-1"  >Remove Photo</button> : ''

                             }

                        </>

                    ) : (
                    <>
                        <span className="fa-stack fa-2x mt-3 mb-2">
                            <i className="fas fa-sharp fa-solid fa-upload"></i>
                        </span>
                        <h5 className="text-center">Upload your photo</h5>

                    </>
                    )}
                </label>
                <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleChange}  />
                
              </div>



          </div> */}
          {/* <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Programme Status
                </label>

                <select
                  name="programme_status"
                  {...register("programme_status")}
                  className={`form-control ${
                    errors.programme_status ? "is-invalid" : ""
                  }`}
                >
                  <option hidden value="">
                    {" "}
                    -- Choose Status --{" "}
                  </option>
                  <option value="Active">Active</option>
                  <option value="InActive">In Active</option>
                </select>
                <div className="invalid-feedback">
                  {errors.programme_status?.message}
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="row" style={{ textAlign: "center" }}>
            <h4 className="header-title mt-0">
              <u>Add Your Promocode</u>
            </h4>
          </div>

          <div className="row mt-2">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Promocode
                </label>
                <input
                  type="text"
                  {...register("promocode")}
                  name="promocode"
                  placeholder="Username"
                  className={`form-control ${
                    errors.promocode ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.promocode?.message}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="field-2" className="form-label">
                      From Date
                    </label>
                    <input
                      type="date"
                      {...register("fromdate")}
                      name="fromdate"
                      placeholder="Username"
                      className={`form-control ${
                        errors.fromdate ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.fromdate?.message}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="field-2" className="form-label">
                      To Date
                    </label>
                    <input
                      type="date"
                      {...register("todate")}
                      name="todate"
                      placeholder="Username"
                      className={`form-control ${
                        errors.todate ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.todate?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    {...register("onetimeuse")}
                    name="onetimeuse"
                    placeholder="Username"
                    className={`form-check-input ${
                      errors.onetimeuse ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.onetimeuse?.message}
                  </div>
                  <label className="form-check-label" htmlFor="checkbox-signin">
                    One Time Use
                  </label>
                </div>
              </div>
            </div>
          </div> */}

          <div className="modal-footer">
            {loading ? (
              <button
                type="button"
                className="btn btn-info waves-effect waves-light"
                disabled={true}
              >
                Loading ...
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-secondary waves-effect"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-info waves-effect waves-light"
                  onClick={handleSubmit(Create_Programme)}
                >
                  Save changes
                </button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={promocode_modal}
        onHide={HandleClose_Promo}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            Create Promocode
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive mt-2">
            <table className="table table-striped table-bordered mb-0">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>#</th>
                  <th style={{ textAlign: "center" }}>Details</th>
                  <th style={{ textAlign: "center" }}>Date</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>

              <tbody>
                {promocodes.length === 0 ? (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      {" "}
                      No Promocodes
                    </td>
                  </tr>
                ) : (
                  <>
                    {promocodes.map((value, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>
                          Code : {value.programme_promocode} <br />
                          From :{" "}
                          {moment(value.promocode_from_date).format(
                            "MM/DD/YYYY"
                          )}{" "}
                          <br />
                          To :{" "}
                          {moment(value.promocode_to_date).format(
                            "MM/DD/YYYY"
                          )}{" "}
                          <br />
                          Percentage : {value.discount_percentage} % <br />
                          Use :{" "}
                          {value.is_onetimeuse === true ? (
                            <b>Single Use</b>
                          ) : (
                            <b>Multiple Use</b>
                          )}{" "}
                          <br />
                        </td>
                        <td>
                          <b>Created :</b>
                          {moment(value.created_date).format("MM/DD/YYYY")}
                          <br />
                          <b>Updated :</b>
                          {moment(value.updated_date).format("MM/DD/YYYY")}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-warning btn-xs waves-effect waves-light tooltips"
                            onClick={() => Single_Promo_Edit(value.id)}
                          >
                            Edit
                          </button>{" "}
                          <br />
                          <button
                            type="button"
                            className="btn btn-danger mt-2 btn-xs waves-effect waves-light tooltips"
                            onClick={() => Delete_Promocode(value.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                    <td>1</td>
                    <td>
                        <b>Code : </b> ghgfdgshhhhhhhhhhhhhhhhhhhhhhhhhhhhhfl <br/>
                    <b>From : </b> 12/ 34/ 3456 <br/>
                    <b>To : </b> 23 / 45 / 567 <br/>
                    <b>One time </b> <br/>
                    <b> Discount Perct</b> 30 %
                    </td>
                    <td>
                        12/ 34/ 3456 <br/>
                        12/ 34/ 3456
                    </td>
                    <td>
                    <button
                        type="button"
                        className="btn btn-danger btn-xs waves-effect waves-light mt-1"
                    >
                        Delete
                    </button> <br>
                    <button
                                      type="button"
                                      className="btn btn-danger btn-xs waves-effect waves-light mt-1"
                                    >
                                      Delete
                                    </button></br>
                    </td>

                    </tr> */}
                  </>
                )}
              </tbody>
            </table>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Promocode
                </label>
                <input
                  type="text"
                  value={promo_code}
                  onChange={(e) => setpromo_code(e.target.value)}
                  className="form-control"
                  name="promocode"
                  placeholder="Promocode"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  From Date
                </label>
                <input
                  type="date"
                  value={from_date}
                  onChange={(e) => setfrom_date(e.target.value)}
                  className="form-control"
                  name="promocode"
                  placeholder="Promocode"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  To Date
                </label>
                <input
                  type="date"
                  value={to_date}
                  onChange={(e) => setto_date(e.target.value)}
                  className="form-control"
                  name="promocode"
                  placeholder="Promocode"
                />
              </div>
            </div>

            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="field-1" className="form-label">
                  Discount %
                </label>
                <input
                  type="number"
                  value={discount_percentage}
                  onChange={(e) => setdiscount_percentage(e.target.value)}
                  className="form-control"
                  name="promocode"
                  placeholder="Discount Percentage"
                />
              </div>
            </div>

            <div className="col-2 mt-4">
              <div className="mb-3">
                <div className="form-check">
                  <label className="form-check-label" htmlFor="checkbox-signin">
                    One Time Use
                  </label>
                  <input
                    type="checkbox"
                    checked={onetime_use === true ? true : false}
                    onChange={() => setonetime_use(!onetime_use)}
                    name="onetimeuse"
                    placeholder="Username"
                    className="form-check-input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2 mb-3">
            <div className="col-8"></div>
            <div className="col-2" style={{ textAlign: "right" }}>
              {promo_loading ? (
                ""
              ) : (
                <button
                  type="button"
                  className="btn btn-warning waves-effect waves-light"
                  onClick={Clear_Promo_Details}
                >
                  Clear
                </button>
              )}
            </div>
            <div className="col-2">
              {promo_loading ? (
                <button
                  type="button"
                  className="btn btn-info waves-effect waves-light"
                  disabled={true}
                >
                  Loading ...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-info waves-effect waves-light"
                  onClick={Add_Promo_Code}
                >
                  Save Code
                </button>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary waves-effect"
              onClick={HandleClose_Promo}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Snackbar
        open={alert.open}
        TransitionComponent={Collapse}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={AlertClose}
      >
        <Alert onClose={AlertClose} severity={alert.severity}>
          {alert.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProgrammePage;
