import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BaseURL } from "../Constants/ConstantURL";
import { SimpleContext } from "../Context/AuthContext";


import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Collapse } from "@mui/material";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} variant="filled" {...props} />;
});

function Enrolled_Pages() {
  let params = useParams();
  const { authtoken } = useContext(SimpleContext);

  const [user_data, setuser_data] = useState("");
  const [assesment_datas, setassesment_datas] = useState("");

  const [enrolled_challenges, setenrolled_challenges] = useState([]);
  const [enrolled_programmes, setenrolled_programmes] = useState([])


  const [alert, setalert] = useState({
    open: false,
    msg: "",
    severity: "",
  });

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    Get_Single_User();
    Get_Enrolled_Challenges();
    Get_All_Enrolled_Programmes()
  }, []);


  const AlertClose = (event, reason) => {
    setalert({ open: false, message: "" , severity : "" });
  };

  const Get_Single_User = async () => {
    
    let config = {
      headers: { Authorization: `Bearer ${authtoken.access}` },
      params: {
        id: params.user_id,
      },
    };

    await axios
      .get(`${BaseURL}/user_details/get_all_users/`, config)
      .then((res) => {
        // setuser_details(res.data)
        console.log("THE SINGLE USER");
        console.log(res.data);
        setuser_data(res.data[0].user_id[0]);
        setassesment_datas(res.data[0]);
      });
  };

  const Get_Enrolled_Challenges = async () => {
    let config = {
      headers: { Authorization: `Bearer ${authtoken.access}` },
      params: {
        user_data_id: params.user_id,
      },
    };

    await axios
      .get(`${BaseURL}/enroll/challenge_enroll_details/`, config)
      .then((res) => {
        console.log("THE CHAKKEBGHGEFGEVG");
        console.log(res.data);
        setenrolled_challenges(res.data);
      });
  };

  const Get_All_Enrolled_Programmes = async () => {

    let config = {
        headers: { Authorization: `Bearer ${authtoken.access}` },
        params: {
          user_data_id: params.user_id,
        },
      };


    await axios.get(`${BaseURL}/enroll/programme_enroll_details/`,config)
    .then((res) => {
        console.log(res.data)
        // setall_enrolled_challenges(res.data)
        console.log("PROGRAMMESHJHJJHJHJK")
        console.log(res.data)
        setenrolled_programmes(res.data)
    })
}


  const Delete_Enrolled_Challenge =  async (id) => {
      if (window.confirm('Do You Want To Delete This Challenge ? ')){
        var data = {
          "id" : id,
          "action" : "Delete_Challenge"
        }

       await axios.post(`${BaseURL}/enroll/delete_enroll_data/`,data,{
        headers : {
          Authorization : `Bearer ${authtoken.access}`
        }
       })
       .then((res) => {
        if (res.data.status === 200)
        {
          setalert({open:true,msg:res.data.message,severity:"success"})
          Get_Enrolled_Challenges()
        }
        else
        {
          setalert({open:true,msg:res.data.message,severity:"error"})

        }
       })
       .catch((error) => {
        setalert({open:true,msg:"Something Went Wrong",severity:"error"})

       })
      }
  }


  const Delete_Enrolled_Programme =  async (id) => {
    if (window.confirm('Do You Want To Delete This Programme ? ')){
      var data = {
        "id" : id,
        "action" : "Delete_Programme"
      }

     await axios.post(`${BaseURL}/enroll/delete_enroll_data/`,data,{
      headers : {
        Authorization : `Bearer ${authtoken.access}`
      }
     })
     .then((res) => {
      if (res.data.status === 200)
      {
        setalert({open:true,msg:res.data.message,severity:"success"})
        Get_All_Enrolled_Programmes()
      }
      else
      {
        setalert({open:true,msg:res.data.message,severity:"error"})

      }
     })
     .catch((error) => {
      setalert({open:true,msg:"Something Went Wrong",severity:"error"})

     })
    }
}

  return (
    <div id="wrapper">
      <div className="content-page">
        <div className="content  mt-4">
          {/* Start Content*/}
          <div className="container-fluid">
            <Link to="/joined-users">
            <button type="button" class="btn btn-info width-md waves-effect waves-light"><i className="mdi mdi-arrow-left-thick"></i>Back</button>
            </Link>
            <div className="row mt-2">
              <div className="col-12">
                <div className="col-xl-12 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mt-0 mb-3">
                        <i className="mdi mdi-account" />
                        User Details{" "}
                      </h4>
                      <hr />
                      <div className="row mt-2">
                        <div className="col-6">
                          <div className="row">
                            <div
                              className="col-4"
                              style={{ textAlign: "right" }}
                            >
                              <b>User Name : </b> <br />
                              <b>Mobile : </b>
                              <br />
                              <b>Last Login : </b>
                              <br />
                              <b>Communication : </b>
                              <br />
                              <b>Email : </b>
                              <br />
                              <b>Full Name : </b>
                              <br />
                              <b>Gender : </b>
                              <br />
                            </div>
                            <div
                              className="col-6"
                              style={{ textAlign: "left" }}
                            >
                              {user_data.username} <br />
                              {user_data.mobile} <br />
                              {moment(user_data.last_login).format(
                                "MM/DD/YYYY"
                              )}
                              <br />
                              {assesment_datas.prefer_of_communication}
                              <br />
                              {user_data.username} <br />
                              {assesment_datas.fullname} <br />
                              {assesment_datas.gender} <br />
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-6">
                          <div className="row">
                            <div
                              className="col-4"
                              style={{ textAlign: "right" }}
                            >
                              <b>Height : </b> <br />
                              <b>Weight : </b>
                              <br />
                              <b>Hip Size : </b>
                              <br />
                              <b>Waits Size : </b>
                              <br />
                            </div>
                            <div
                              className="col-6"
                              style={{ textAlign: "left" }}
                            >
                              {assesment_datas.height_cm} Cm{" "}
                              {assesment_datas.height_inch} Inch <br />
                              {assesment_datas.weight_kg} Kg{" "}
                              {assesment_datas.weight_lb} Pound <br />
                              {assesment_datas.hip_cm} Cm{" "}
                              {assesment_datas.hip_inch} Inch <br />
                              {assesment_datas.waist_cm} Cm{" "}
                              {assesment_datas.waist_inch} Inch <br />
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <div className="col-xl-12 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mt-0 mb-3">
                        {/* <i className="mdi mdi-filter-outline" /> */}
                        Challenges{" "}
                      </h4>
                      <hr />
                      <div className="row">
                        <div className="col-md-3 col-sm-12">
                          <label
                            htmlFor="example-palaceholder"
                            className="form-label"
                          >
                            Name :
                          </label>
                          <input
                            type="text"
                            id="example-palaceholder"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-6 col-sm-12"
                          style={{ textAlign: "right" }}
                        ></div>
                        <div
                          className="col-md-6 col-sm-12 searchbtndiv"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            type="button"
                            className="btn btn-danger waves-effect waves-light"
                            style={{ marginRight: "5px" }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <h4 className="header-title mt-0 mb-3">
                       
                        Enrolled Challenges{" "}
                      </h4>
                      <hr />

                      <div className="table-responsive mt-2">
                        <table className="table table-striped table-bordered mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Challenge Details</th>
                              <th>Steps Details</th>
                              <th>Price Details</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {enrolled_challenges.length === 0 ? (
                              <tr>
                                <td colSpan={5} style={{ textAlign: "center" }}>
                                  {" "}
                                  No Enrolled Challenge Here
                                </td>
                              </tr>
                            ) : (
                              <>
                                {enrolled_challenges.map((value, key) => (
                                  <tr key={key}>
                                    <th
                                      scope="row"
                                      style={{ textAlign: "center" }}
                                    >
                                      {key + 1}
                                    </th>

                                    <td>
                                      <b> Challenge Name : </b>
                                      <span>
                                        {" "}
                                        {
                                          value.challenge_details[0]
                                            .challenge_name_english
                                        }
                                      </span>
                                      <br />
                                      <b> Challenge Type : </b>
                                      <span> {value.challenge_type}</span>
                                      <br />
                                      {value.is_custom_steps === false ? (
                                        <>
                                          <b> Activity Type : </b>
                                          <span> {value.activity_level}</span>
                                          <br />{" "}
                                        </>
                                      ) : (
                                        <>
                                          <b> Custom Steps : </b>
                                          <span> {value.custom_steps}</span>
                                          <br />{" "}
                                        </>
                                      )}
                                      <b> Joined Date : </b>
                                      <span>
                                        {" "}
                                        {moment(value.created_date).format(
                                          "MM/DD/YYYY"
                                        )}{" "}
                                      </span>
                                      <br />

                                      {/* <b> Challenge From Date : </b><span> { value.challenge_details[0].challenge_from_date }</span><br/>
                                                <b> Challenge To Date : </b><span> { value.challenge_details[0].challenge_to_date }</span><br/> */}
                                    </td>

                                    <td>

                                    <b> Steps To Complete : </b>
                                      <span>
                                        {" "}
                                        {
                                          value.steps_to_complete
                                        }
                                      </span>
                                      <br />

                                      <b> Steps Completed : </b>
                                      <span>
                                        {" "}
                                        {
                                          value.steps_completed
                                        }
                                      </span>
                                      <br />



                                      
                                      <b> Steps To Complete : </b><span> { value.steps_to_complete - value.steps_completed } </span>
                                      <span>
                                        {" "}
                                        
                                        {/* {
                                          Math.round(
                                          (value.steps_completed / value.steps_to_complete) * 100  
                                          )
                                        } % */}

                                        <div class="progress mb-0" style={{ height:"20px" }}>
                                            <div class="progress-bar" style={{  width : `${(Math.round((value.steps_completed / value.steps_to_complete) * 100 ))}%`, backgroundColor:`${(Math.round((value.steps_completed / value.steps_to_complete) * 100 )) === 100 ? 'green' : '#71b6f9' }` }} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{
                                          Math.round(
                                          (value.steps_completed / value.steps_to_complete) * 100  
                                          )
                                        } % </div>
                                        </div>


                                      </span>
                                      <br />
                                      
                                    </td>

                                    <td>
                                      {value.is_free === true ? (
                                        <>
                                          <b> Free Challenge</b>
                                          <br />
                                        </>
                                      ) : (
                                        <>
                                          <b> Challenge Price : </b>
                                          <span> {value.challenge_price}</span>
                                          <br />
                                          <b> Discount Price : </b>
                                          <span> {value.discount_price}</span>
                                          <br />
                                          <b> Paid Price : </b>
                                          <span> {value.payable_amount}</span>
                                          <br />

                                          {value.discount_price != null ? (
                                            <>
                                              <b> Discount Percentage : </b>
                                              <span>
                                                {" "}
                                                {
                                                  value.coupouncode_details[0]
                                                    .discount_percentage
                                                }{" "}
                                                %
                                              </span>
                                              <br />
                                              {value.coupouncode_details[0]
                                                .is_onetimeuse === true ? (
                                                <b>One Tine Use</b>
                                              ) : (
                                                <b>Multiple Use</b>
                                              )}
                                              <br />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {/* <span>                 
                                                            <div className="external-event bg-success" data-class="bg-pink" style={{ textAlign:"center" , width:"50%"}}> { value.paid_status }</div>
                                                        </span> 
                                                */}

                                          <br />
                                        </>
                                      )}
                                    </td>
                                    <td>
                                    <button type="button" className="btn btn-danger btn-xs waves-effect waves-light mt-1" onClick={()=>Delete_Enrolled_Challenge(value.id)} >Delete</button>

                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <div className="col-xl-12 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mt-0 mb-3">
                        {/* <i className="mdi mdi-filter-outline" /> */}
                        Programmes{" "}
                      </h4>
                      <hr />
                      <div className="row">
                        <div className="col-md-3 col-sm-12">
                          <label
                            htmlFor="example-palaceholder"
                            className="form-label"
                          >
                            Name :
                          </label>
                          <input
                            type="text"
                            id="example-palaceholder"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-6 col-sm-12"
                          style={{ textAlign: "right" }}
                        ></div>
                        <div
                          className="col-md-6 col-sm-12 searchbtndiv"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            type="button"
                            className="btn btn-danger waves-effect waves-light"
                            style={{ marginRight: "5px" }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <h4 className="header-title mt-0 mb-3">
                        {/* <i className="mdi mdi-filter-outline" /> */}
                        Enrolled Programmes{" "}
                      </h4>
                      <hr />

                      <div className="table-responsive mt-2">
                        <table className="table table-striped table-bordered mb-0">
                          <thead>
                            <tr>
                                <th style={{ textAlign: "center" }}>#</th>
                                <th style={{ textAlign: "center" }}>Programme Details</th>
                                <th style={{ textAlign: "center" }}>Payment Details</th>
                                <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                                enrolled_programmes.length === 0 ? <tr><td colSpan={4} style={{ textAlign:"center"}}> No Enrolled Programmes </td></tr> :
                                <>
                                {
                                    enrolled_programmes.map((value,key) => (
                                        <tr key={key}>
                                        <th
                                            scope="row"
                                            style={{ textAlign: "center" }}>
                                            {key + 1}
                                        </th>
                                        <td>
                                       <b>   Programme Name :  </b><span> {  value.programme_details[0].programmename_english }</span><br/>
                                    </td>
                                    <td>
                                      {
                                        value.is_free === true ? 
                                        <>
                                       <b>   Programme Price :  </b><span> Free of Coast </span><br/>
                                       </> :
                                       <>
                                          <b>   Programme Price :  </b><span> {  value.programme_price }</span><br/>
                                          <b>   Discount Price :  </b><span> {  value.discount_price === null ? `No Discout Added` : `${value.discount_price}` }</span><br/>
                                          <b>   Payable Amount :  </b><span> {  value.payable_amount  }</span><br/>
                                       </>


                                      }
                                    </td>
                                    <td>
                                    <button type="button" className="btn btn-danger btn-xs waves-effect waves-light mt-1" onClick={()=>Delete_Enrolled_Programme(value.id)} >Delete</button>

                                    </td>

                                        </tr>
                                    ))
                                }
                                </>
                            }
                          </tbody>
                                                

                       
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <Snackbar
      open={alert.open}
      TransitionComponent={Collapse}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={3000}
      onClose={AlertClose}
    >
      <Alert onClose={AlertClose} severity={alert.severity}>
        {alert.msg}
      </Alert>
    </Snackbar>
    </div>
  );
}

export default Enrolled_Pages;
