import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { BaseURL } from '../Constants/ConstantURL'
import { SimpleContext } from '../Context/AuthContext'

function Enrolled_Programme() {

    const { authtoken } = useContext(SimpleContext)

    const [enrolled_programme_details, setenrolled_programme_details] = useState([])



    useEffect(() => {
        Get_All_Enrolled_Programmes()
    }, [])
    


    const Get_All_Enrolled_Programmes = async () => {
        await axios.get(`${BaseURL}/enroll/programme_enroll_details/`,{
            headers : {
                Authorization : `Bearer ${authtoken.access}`
            }
        })
        .then((res) => {
            console.log(res.data)
            // setall_enrolled_challenges(res.data)
            setenrolled_programme_details(res.data)
        })
    }



  return (
    <div id="wrapper">

    <div className="content-page">
      <div className="content  mt-4">
        {/* Start Content*/}
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col-12">
              <div className="col-xl-12 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">
                      <i className="mdi mdi-filter-outline" />
                      Filter{" "}
                    </h4>
                    <hr />
                    <div className="row">
                      <div className="col-md-3 col-sm-12">
                        <label
                          htmlFor="example-palaceholder"
                          className="form-label"
                        >
                          Name :
                        </label>
                        <input
                          type="text"
                          id="example-palaceholder"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-md-6 col-sm-12"
                        style={{ textAlign: "right" }}
                      ></div>
                      <div
                        className="col-md-6 col-sm-12 searchbtndiv"
                        style={{ textAlign: "right" }}
                      >
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          style={{ marginRight: "5px" }}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <h4 className="mt-0 header-title mb-2">Enrolled Programmes</h4>
                    </div>
                    <div className="col-6" style={{ textAlign: "right" }}>
                      {/* <button
                        type="button"
                        className="btn btn-info btn-xs waves-effect waves-light"
                        // onClick={() => EditData(null)}
                      >
                        {" "}
                        Create Programme
                      </button> */}
                    </div>
                  </div>

                  <div className="table-responsive mt-2">
                    <table className="table table-striped table-bordered mb-0">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>#</th>
                          <th style={{ textAlign: "center" }}>Programme Details</th>
                          <th style={{ textAlign: "center" }}>Payment Details</th>
                     
                          <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          enrolled_programme_details.length === 0 ? <tr> <td colSpan={4} style={{ textAlign : "center"}} > No Enrolled Programme</td></tr> : <>
                            {
                              enrolled_programme_details.map((value,key) => (
                                <tr key={key}>
                                    <th scope="row" style={{ textAlign:"center"}} >{key + 1}</th>
                                    <td>
                                       <b>   Programme Name :  </b><span> {  value.programme_details[0].programmename_english }</span><br/>
                                    </td>
                                    <td>
                                      {
                                        value.is_free === true ? 
                                        <>
                                       <b>   Programme Price :  </b><span> Free of Coast </span><br/>
                                       </> :
                                       <>
                                          <b>   Programme Price :  </b><span> {  value.programme_price }</span><br/>
                                          <b>   Discount Price :  </b><span> {  value.discount_price === null ? `No Discout Added` : `${value.discount_price}` }</span><br/>
                                          <b>   Payable Amount :  </b><span> {  value.payable_amount  }</span><br/>
                                       </>


                                      }
                                    </td>
                         
                                </tr>

                              ))
                            }
                          </>
                        }
                      </tbody>
                      {/* <tbody>
                        {user_details.length === 0 ? <tr>
                            <td colSpan={4} style={{ textAlign: "center" }}><b>No Users Joined</b>  </td>
                        </tr> :
                        <>
                            { user_details.map((value,key) => (
                                <tr key={key}>
                                    <th scope="row" style={{ textAlign:"center"}} >{key + 1}</th>
                                    <td>
                                        <b> Full Name : </b><span> { value.fullname }</span><br/>
                                        <b> Age : </b><span> { value.age }</span><br/>
                                        <b> Gender : </b><span> { value.gender }</span><br/>
                                        <b> Mobile : </b><span> { value.mobile }</span><br/>
                                        <b> Email : </b><span> { value.user_id[0].username }</span><br/>
                                        <b> Joined Date : </b><span>{moment(value.created_date).format("MM/DD/YYYY")}</span><br/>
                                        <b> Last Login : </b><span>{moment(value.user_id[0].last_login).format("MM/DD/YYYY")}</span><br/>
                                    </td>
                                    <td>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <b> Height in Cm : </b><span> { value.height_cm }</span><br/>
                                                <b> Weight in Kg : </b><span> { value.weight_kg }</span><br/>
                                                <b> Waist Size in Cm : </b><span> { value.waist_cm }</span><br/>
                                                <b> Hip Size in Cm : </b><span> { value.hip_cm }</span><br/>
                                            </div>
                                            <div className='col-6'>
                                                <b> Height in Inch : </b><span> { value.height_inch }</span><br/>               
                                                <b> Weight in Pound : </b><span> { value.weight_lb }</span><br/>               
                                                <b> Waist Size in Inch : </b><span> { value.waist_inch }</span><br/>               
                                                <b> Hip Size in Inch : </b><span> { value.hip_inch }</span><br/>   

                                            </div>
                                        </div>
                                       
                                    </td>
                                    <td>
             
                                  <br/>
                                    {value.user_id[0].is_active === true ?
                                     <button
                                     type="button"
                                     className="btn btn-success btn-xs waves-effect waves-light tooltips"
                                     onClick={() => Change_Status(value.user_id[0].id)}
                                   >
                                     Active
                                   </button> :
                                    <button
                                    type="button"
                                    className="btn btn-danger btn-xs waves-effect waves-light tooltips"
                                    onClick={() => Change_Status(value.user_id[0].id)}
                                  >
                                    Block
                                  </button>
                                     }
                                    </td>
                                </tr>
                            ))}
                        </>
                        }
                      </tbody> */}

                      {/* <tbody>
                        {all_programme.length === 0 ? (
                          <tr>
                            <td colSpan={8} style={{ textAlign: "center" }}>
                              {" "}
                              No Programmes Found
                            </td>
                          </tr>
                        ) : (
                          <>
                            {all_programme.map((value, key) => (
                              <tr key={key}>
                                <th scope="row">{key + 1}</th>
                                <td>
                                  <b>Eng :</b> {value.programmename_english}
                                  <br />
                                  <b>Arab :</b>
                                  {value.programmename_arabic}
                                </td>
                                <td>
                                  {value.programme_price}
                                  <b>USD</b>
                                </td>
                                <td style={{ display: "flex" }}>
                                  <img
                                    src =  { value.programme_image }
                                    style={{
                                      height: "100px",
                                      width: "80px",
                                      objectFit: "contain",
                                    }}
                                    alt=""
                                  />
                                </td>
                             
                                <td>
                                  <b>Created :</b>
                                  {moment(value.created_date).format(
                                    "MM/DD/YYYY"
                                  )}
                                  <br />
                                  <b>Updated :</b>
                                  {moment(value.updated_date).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>
                                <td>
                                  {
                                    value.programme_price === "" || value.programme_price === null || value.programme_price === 0 ?
                                    <button
                                    type="button"
                                    className="btn btn-primary btn-xs waves-effect waves-light"
                        
                                    style={{ cursor : "not-allowed" }}
                                 >
                                    Promocode
                                  </button>
                                    :
                                    <button
                                    type="button"
                                    className="btn btn-primary btn-xs waves-effect waves-light"
                                    onClick={()=>Fetch_Promocodes(value.id)}>
                                    Promocode
                                  </button>
                                  }
                               
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-warning btn-xs waves-effect waves-light tooltips"
                                    onClick={() => EditData(value.id)}
                                  >
                                    Edit
                                  </button>
                                  <br />
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-xs waves-effect waves-light mt-1"
                                  >
                                    Delete
                                  </button>
                                  <br />
                                  {value.programme_status === "Active" ?<button type="button" className="btn btn-success btn-xs waves-effect waves-light mt-1" onClick={()=>StatusChange(value.id)}>Active</button> :
                                  <button type="button" className="btn btn-danger btn-xs waves-effect waves-light mt-1" onClick={()=>StatusChange(value.id)}>Block</button>}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody> */}



                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    {/* <Modal
      show={create_programme_modal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center" }}>
          Create Programme
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

          <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="field-1" className="form-label">
                Programme Name ( English )
              </label>
              <input
                type="text"
                {...register("programmename_english")}
                name="programmename_english"
                placeholder="Programme Name ( English )"
                className={`form-control ${
                  errors.programmename_english ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.programmename_english?.message}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="field-1" className="form-label">
                Programme Name ( Arabic )
              </label>
              <input
                type="text"
                {...register("programmename_arabic")}
                name="programmename_arabic"
                placeholder=" Programme Name ( Arabic )"
                className={`form-control ${
                  errors.programmename_arabic ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.programmename_arabic?.message}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="field-1" className="form-label">
                Programme Price
              </label>
              <input
                type="text"
                {...register("programme_price")}
                name="programme_price"
                placeholder="Programme Price"
                className={`form-control ${
                  errors.programme_price ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.programme_price?.message}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="field-1" className="form-label">
                Programme Status
              </label>

              <select
                name="programme_status"
                {...register("programme_status")}
                className={`form-control ${
                  errors.programme_status ? "is-invalid" : ""
                }`}
              >
                <option hidden value="">
                  {" "}
                  -- Choose Status --{" "}
                </option>
                <option value="Active">Active</option>
                <option value="InActive">In Active</option>
              </select>
              <div className="invalid-feedback">
                {errors.programme_status?.message}
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="mb-3">
              <label htmlFor="field-1" className="form-label">
                Programme Description ( English )
              </label>
              <textarea  rows={5}
              {...register("programme_details_english")}
              name="programme_details_english"
              className={`form-control ${
                errors.programme_details_english ? "is-invalid" : ""
              }`}/>
                  <div className="invalid-feedback">
                {errors.programme_details_english?.message}
              </div>
              
            </div>
        
          </div>

          <div className="col-12">
            <div className="mb-3">
              <label htmlFor="field-1" className="form-label">
                Programme Description ( Arabic )
              </label>
              <textarea  rows={5}
              {...register("programme_details_arabic")}
              name="programme_details_arabic"
              className={`form-control ${
                errors.programme_details_arabic ? "is-invalid" : ""
              }`}/>
              <div className="invalid-feedback">
                {errors.programme_details_arabic?.message}
              </div>
              
            </div>
            
          </div>


          <div className='col-6' style={{ textAlign:"center"}}>
              <label htmlFor="upload-button"  style={{ textAlign:"center"}}>
                  {image.preview != null ? (
                      <>
                          <img src={image.preview}  width="150px" height="200px" style={{ objectFit:"contain" }} /> <br/>

               
                          { image.raw === "" ? 
                          <button type="button" className="btn btn-danger btn-xs waves-effect waves-light mt-1 mb-2" onClick={DeleteImage}  >Remove Photo</button> : ''

                           }

                      </>

                  ) : (
                  <>
                      <span className="fa-stack fa-2x mt-3 mb-2">
                          <i className="fas fa-sharp fa-solid fa-upload"></i>
                      </span>
                      <h5 className="text-center">Programme Image</h5>

                  </>
                  )}
              </label>
              <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleChange}  />
              
            </div>
          </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary waves-effect"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-info waves-effect waves-light"
            onClick={handleSubmit(Create_Programme)}
          >
            Save changes
          </button>
        </div>
      </Modal.Body>
    </Modal>

 */}





  



    {/* <Snackbar
      open={alert.open}
      TransitionComponent={Collapse}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={3000}
      onClose={AlertClose}
    >
      <Alert onClose={AlertClose} severity={alert.severity}>
        {alert.msg}
      </Alert>
    </Snackbar> */}
  </div>
  )
}

export default Enrolled_Programme