import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Outlet_Connection from "./Connection/Outlet_Connection";
import AuthContext from "./Context/AuthContext";
import AdminPage from "./Pages/AdminPage";
import AdminsPage from "./Pages/AdminsPage";
import Challengepage from "./Pages/ChallengePage";
import Dashboard from "./Pages/Dashboard";
import Enrolled_Challenge from "./Pages/Enrolled_Challenge";
import Enrolled_Pages from "./Pages/Enrolled_Pages";
import Enrolled_Programme from "./Pages/Enrolled_Programme";
import Joined_Users from "./Pages/Joined_Users";
import LoginPage from "./Pages/LoginPage";
import Not_Found_page from "./Pages/Not_Found_page";
import ProfilePage from "./Pages/ProfilePage";
import ProgrammePage from "./Pages/ProgrammePage";
import { PrivateRoute } from "./utils/PrivateRoutes";

function App() {
  return (
    <Router>
      <AuthContext>
        <Routes>

          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute><Outlet_Connection/></PrivateRoute>}>
              <Route index element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              {/* <Route path="create-admin" element={<PrivateRoute><AdminPage /></PrivateRoute>} /> */}
              <Route path="create-admin" element={<PrivateRoute><AdminsPage /></PrivateRoute>} />
              <Route path="create-programmes" element={<PrivateRoute><ProgrammePage /></PrivateRoute>} />
              <Route path="create-challenge" element={<PrivateRoute><Challengepage /></PrivateRoute>} />
              <Route path="joined-users" element={<PrivateRoute><Joined_Users /></PrivateRoute>} />
              <Route path="enrolled-challenges" element={<PrivateRoute><Enrolled_Challenge /></PrivateRoute>} />
              <Route path="enrolled-programmes" element={<PrivateRoute><Enrolled_Programme /></PrivateRoute>} />
              <Route path="enrolled-datas/:user_id" element={<PrivateRoute><Enrolled_Pages /></PrivateRoute>} />
              <Route path="profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
          </Route>
          <Route path="*" element={<Not_Found_page />} />

          {/* <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/create-admin" element={<PrivateRoute><AdminPage /></PrivateRoute>} />
          <Route path="/create-programmes" element={<PrivateRoute><ProgrammePage /></PrivateRoute>} />
          <Route path="/create-challenge" element={<PrivateRoute><Challengepage /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} /> */}

        </Routes>
      </AuthContext>
    </Router>
  );
}

export default App;
