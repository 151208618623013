import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { BaseURL } from '../Constants/ConstantURL'
import { SimpleContext } from '../Context/AuthContext'

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Collapse } from "@mui/material";

import { Modal } from "react-bootstrap";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert ref={ref} variant="filled" {...props} />;
  });

function ProfilePage() {


    const { authtoken , Logout , setis_sidebar } = useContext(SimpleContext)

    const [loading, setloading] = useState(false)


    const [alert, setalert] = useState({
        open: false,
        msg: "",
        severity: "",
      });

    const [change_pass_modal, setchange_pass_modal] = useState(false)

    const [id, setid] = useState("")
    const [last_login, setlast_login] = useState("")
    const [is_superuser, setis_superuser] = useState("")
    const [is_admin, setis_admin] = useState("")
    const [username, setusername] = useState("")
    const [mobile, setmobile] = useState("")
    const [address, setaddress] = useState("")
    const [gender, setgender] = useState("")
    const [dob, setdob] = useState("")
    const [email, setemail] = useState("")
    const [first_name, setfirst_name] = useState("")
    const [last_name, setlast_name] = useState("")
    const [user_prof_image, setuser_prof_image] = useState("")

    const [is_edit, setis_edit] = useState(false)

  const [image, setImage] = useState({ preview:null, raw: "" });


  const [old_password, setold_password] = useState("")
  const [new_password, setnew_password] = useState("")


    



    useEffect(() => {
      setis_sidebar(false)
        Get_User_Details()
    }, [])

    const AlertClose = (event, reason) => {
        setalert({ open: false, message: "" , severity : "" });
      };


    const handleChange = e => {
        if (e.target.files.length) {
          setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
          });
        console.log(e.target.files[0])
        }
      };

    const Get_User_Details = () => {
        var data = {
            "action" : "Fetch_Data"
        }
        axios.post(`${BaseURL}/user_details/fetch_user/`,data,{
            headers : {
                Authorization : `Bearer ${authtoken.access}`
            }
        })
        .then((res) => {
            console.log(res.data.message)
            if (res.data.status === 200) {
                if (res.data.message.length != 0) {
                    var data = res.data.message
                    setid(data.id)
                    setlast_login(data.last_login)
                    setis_superuser(data.is_superuser)
                    setis_admin(data.is_admin)
                    setusername(data.username)
                    setmobile(data.mobile)
                    setaddress(data.address)
                    setgender(data.gender)
                    setdob(data.dob)
                    setemail(data.email)
                    setfirst_name(data.first_name)
                    setlast_name(data.last_name)
                    setuser_prof_image(data.user_prof_image)
                }
            }
        })
    }


    const Check_Edit = () => {
        setis_edit(!is_edit)
        Get_User_Details()
    }

    const update_Profile = () => {
        var form_data = new FormData()

        form_data.append("id" , id)
        form_data.append("last_login" , last_login)
        form_data.append("is_superuser" , is_superuser)
        form_data.append("is_admin" , is_admin)
        form_data.append("username" , username)

        form_data.append("action" , "Update_Profile")

        if (dob != null) {
            form_data.append("dob" , dob)
        }

        if (mobile != null) {
            form_data.append("mobile" , mobile)
        }

        if (address != null) {
            form_data.append("address" , address)
        }

        if (gender != null) {
            form_data.append("gender" , gender)
        }

        if (email != null) {
            form_data.append("email" , email)
        }

        if (first_name != null) {
            form_data.append("first_name" , first_name)
        }

        if (last_name != null) {
            form_data.append("last_name" , last_name)
        }


        if ( image.raw != "") {
            form_data.append("user_prof_image" , image.raw)
        }

        axios.post(`${BaseURL}/user_details/fetch_user/`,form_data,{
            headers: {
                Authorization : `Bearer ${authtoken.access}`
            }
        })
        .then((res) => {

            if (res.data.status === 200) {
                setalert({open:true,msg:res.data.message,severity:"success"})
                Get_User_Details()
                setis_edit(false)
                setImage({ preview : null , raw : ""})

            }
            else{
                setalert({open:true,msg:res.data.message,severity:"error"})

            }
            
        })
        .catch((error) => {
            setalert({open:true,msg:"Something Went Wrong",severity:"error"})

        })
    
    }

    const Update_Password = () => {



        if (old_password === "" && new_password === "") {
            var data  = {
                "action" : "Update_username",
                "username" :  username
            }

            axios.post(`${BaseURL}/user_details/fetch_user/`,data,{
                headers : {
                    Authorization : `Bearer ${authtoken.access}`
                }
            })
            .then((res) => {
                if (res.data.status === 200) {
                    // setalert({open:true,msg:res.data.message,severity:"success"})
                    // Get_User_Details()
                    // setchange_pass_modal(false)
                    Logout("The Username Changed Succesfully , Please Re Login With New Username and Password")
                }
                else{
                    setalert({open:true,msg:res.data.message,severity:"error"})
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    setalert({open:true,msg:error.response.data.username,severity:"error"})
                }
            })
        }
        else{

            if (old_password === "") {
                setalert({open:true,msg:"Old Password is Required",severity:"error"})         
            }
            else if (new_password === "") {
                setalert({open:true,msg:"New Password is Required",severity:"error"})         

            }
            else if (new_password.length < 5) {
                setalert({open:true,msg:"The New Password Length Must be 5 Letters",severity:"error"})         

            }
            else{
                var data  = {
                    "action"  : "Update_Password",
                    "old_password" : old_password,
                    "username" : username,
                    "password" : new_password
                }

                axios.post(`${BaseURL}/user_details/fetch_user/`,data,{
                    headers : {
                        Authorization : `Bearer ${authtoken.access}`
                    }
                })
                .then((res) => {
                    if (res.data.status === 200) {   
                        Logout("The Password Changed Succesfully , Please Re Login With New Username and Password")
                    }

                    else{
                        setalert({open:true,msg:res.data.message,severity:"error"})
                    }
                })
                .catch((error) => {
                    console.log(error.response.status)
                    if (error.response.status === 400) {
                        setalert({open:true,msg:error.response.data.username,severity:"error"})
                    }
                })
            }

        }

        // if ( old_password != "" ) {
        //     if (new_password === "") {
        //         setalert({open:true,msg:"New Password is Required",severity:"error"})
        //     }
        //     else if (new_password.length < 5) {
        //         setalert({open:true,msg:"Password Length Must be 5 Letters",severity:"error"})
        //     }
        //     else{
        //         var data  = {
        //             "action"  : "Update_Password",
        //             "old_password" : old_password,
        //             "username" : username,
        //             "password" : new_password
        //         }
        //     }
        // }
        // else if (new_password != "") {
        //     if (old_password === "") {
        //         setalert({open:true,msg:"Old Password is Required",severity:"error"})

        //     }
        // }

        // if (username === "" || old_password === "" || new_password === "") {
        //     setalert({open:true,msg:"Please Fill All Fields",severity:"error"})
        // }
        // else if (new_password.length < 5) {
        //     setalert({open:true,msg:"The Password Length Have Minimum 5 Letters",severity:"error"})
        // }
        // else{
        //     var data  = {
        //         "action"  : "Update_Password",
        //         "old_password" : old_password,
        //         "username" : username,
        //         "password" : new_password
        //     }

        //     axios.post(`${BaseURL}/user_details/fetch_user/`,data,{
        //         headers : {
        //             Authorization : `Bearer ${authtoken.access}`
        //         }
        //     })
        //     .then((res) => {
        //         if (res.data.status === 200) {   
        //             Logout()
        //         }

        //         else{
        //             setalert({open:true,msg:res.data.message,severity:"error"})
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error.response.status)
        //         if (error.response.status === 400) {
        //             setalert({open:true,msg:error.response.data.username,severity:"error"})
        //         }
        //     })

        // }
     

  
    }


    
  return (
   <div>
  <div id="wrapper">
   
   
    <div className="content-page">
      <div className="content mt-4">

        <div className="container-fluid">


          {/* <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-between">
                    <div className="col-md-4">
                      <div className="mt-3 mt-md-0">
                        <button type="button" className="btn btn-success waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#custom-modal"><i className="mdi mdi-plus-circle me-1" /> Add contact</button>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <form className="d-flex flex-wrap align-items-center justify-content-sm-end">
                        <label htmlFor="status-select" className="me-2">Sort By</label>
                        <div className="me-sm-2">
                          <select className="form-select my-1 my-md-0" id="status-select">
                            <option selected>All</option>
                            <option value={1}>Name</option>
                            <option value={2}>Post</option>
                            <option value={3}>Followers</option>
                            <option value={4}>Followings</option>
                          </select>
                        </div>
                        <label htmlFor="inputPassword2" className="visually-hidden">Search</label>
                        <div>
                          <input type="search" className="form-control my-1 my-md-0" id="inputPassword2" placeholder="Search..." />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div> */}
      
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 ">
              <div className="card">
                <div className="text-center card-body">

                    <div className="dropdown float-end">
                        <button type="button" style={{ float:"right"}} onClick={()=>Check_Edit()} className="btn btn-primary  waves-effect waves-light">Edit</button>
                    </div>




                  <div>
                    { 
                        user_prof_image === null ?  
                    <img src="/assets/images/Hello_Health/avatar.jpg" className="rounded-circle avatar-xl img-thumbnail mb-2" alt="profile-image" style={{ height:"150px" , width:"150px"}} />
                                :
                    <img src={`${BaseURL}${user_prof_image}`} className="rounded-circle avatar-xl img-thumbnail mb-2" alt="profile-image" style={{ height:"150px" , width:"150px"}} />

                    }
                    <p>
                        { is_superuser === true ? <b>Super User</b> : <b> Admin </b>}
                    </p>
{/*           
                    <div className="text-start">
                      <p className="text-muted font-13"><strong>Full Name :</strong> <span className="ms-2">Johnathan Deo</span></p>
                      <p className="text-muted font-13"><strong>Mobile :</strong><span className="ms-2">(123) 123 1234</span></p>
                      <p className="text-muted font-13"><strong>Email :</strong> <span className="ms-2">coderthemes@gmail.com</span></p>
                      <p className="text-muted font-13"><strong>Location :</strong> <span className="ms-2">USA</span></p>
                    </div> */}

                    <div className='row mt-4'>
                    <div className="col-12 col-md-4">
                    <div className="mb-3">
                        <label htmlFor="field-1" className="form-label">First Name</label>
                        <input type="text" value={first_name} onChange={(e) => setfirst_name(e.target.value)} className='form-control' style={{ cursor : `${ is_edit === false ? "no-drop" : ''}`}} readOnly = { is_edit === true ? false : true}  />
                    </div>

                    </div>
                    <div className="col-12 col-md-4">
                    <div className="mb-3">
                        <label htmlFor="field-1" className="form-label">Last Name</label>
                        <input type="text" value={last_name} onChange={(e) => setlast_name(e.target.value)} className='form-control' style={{ cursor : `${ is_edit === false ? "no-drop" : ''}`}} readOnly = { is_edit === true ? false : true}  />
                    </div>
                    </div>

                    <div className="col-12 col-md-4">
                    <div className="mb-3">
                        <label htmlFor="field-1" className="form-label">email</label>
                        <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className='form-control' style={{ cursor : `${ is_edit === false ? "no-drop" : ''}`}} readOnly = { is_edit === true ? false : true} />
                    </div>
                    </div>


                    <div className="col-12 col-md-4">
                    <div className="mb-3">
                        <label htmlFor="field-1" className="form-label">Mobile</label>
                        <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className='form-control' style={{ cursor : `${ is_edit === false ? "no-drop" : ''}`}} readOnly = { is_edit === true ? false : true}  />
                    </div>
                    </div>

                    <div className="col-12 col-md-4">
                    <div className="mb-3">
                        <label htmlFor="field-1" className="form-label">DOB</label>
                        <input type="date" value={dob} onChange={(e) => setdob(e.target.value)} className='form-control' style={{ cursor : `${ is_edit === false ? "no-drop" : ''}`}} readOnly = { is_edit === true ? false : true}  />
                    </div>
                    </div>

                    <div className="col-12 col-md-4">
                    <div className="mb-3">
                        <label htmlFor="field-1" className="form-label">Gender</label>
                        <select value={gender} onChange={(e) => setgender(e.target.value)} className='form-control' style={{ cursor : `${ is_edit === false ? "no-drop" : ''}`}} readOnly = { is_edit === true ? false : true} >
                            <option value="" selected disabled> -- Choose Gender --</option>
                            <option value="Male">  Male </option>
                            <option value="Female"> Female </option>
                        </select> 
                    </div>
                    </div>


                    <div className="col-12 col-md-6">
                    <div className="mb-3">
                        <label htmlFor="field-1" className="form-label">Address</label>
                        <textarea  rows={5} value={address} onChange={(e) => setaddress(e.target.value)} style={{ cursor : `${ is_edit === false ? "no-drop" : ''}`}} readOnly = { is_edit === true ? false : true} className='form-control'  />
                    </div>
                    </div>

                    {/* <div className="col-12 col-md-4">
                    <div className="mb-3">
                        <label htmlFor="field-1" className="form-label">Username</label>
                        <input type="text" value={username} onChange={(e) => setusername(e.target.value)} style={{ cursor : `${ is_edit === false ? "no-drop" : ''}`}}  readOnly = { is_edit === true ? false : true} className='form-control'  />
                    </div>
                    </div> */}

                <div className='col-12 col-md-6' style={{ textAlign:"center"}}>
                <label htmlFor="upload-button"  style={{ textAlign:"center"}}>
                    {image.preview != null ? (
                        <>
                            <img src={image.preview}  width="150px" height="200px" style={{ objectFit:"contain" }} /> <br/>

                 
                            { image.raw === "" ? 
                            <button type="button" className="btn btn-danger btn-xs waves-effect waves-light mt-1 mb-2" >Remove Photo</button> : ''

                             }

                        </>

                    ) : (
                    <>
                        <span className="fa-stack fa-2x mt-3 mb-2">
                            <i className="fas fa-sharp fa-solid fa-upload"></i>
                        </span>
                        <h5 className="text-center">Profile Image</h5>

                    </>
                    )}
                </label>
                <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleChange}  />
                
              </div>






                    </div>
                    { is_edit === true ?
                    <button type="button" style={{ float:"right"}} className="btn btn-primary  waves-effect waves-light" onClick={()=>update_Profile()} >Save</button>
                 : ''}
                    <button type="button" style={{ float:"right" , marginRight:"10px"}} onClick={()=>setchange_pass_modal(true)} className="btn btn-primary  waves-effect waves-light">Change Password</button>


                  </div>
                </div>
              </div>
            </div> 
  
          </div>
       
      
     
        </div> 
      </div> 
      
    </div>




    
    <Modal
        show={change_pass_modal}
        onHide={()=>setchange_pass_modal(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
        
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign:"center"}}>Create Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
   
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">Username</label>
            <input type="text" value={username} onChange={(e)=>setusername(e.target.value)} className='form-control' placeholder='Username' />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">Old Password</label>
            <input type="text" value={old_password} onChange={(e)=>setold_password(e.target.value)} className='form-control' placeholder='Old Password' />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="mb-3">
            <label htmlFor="field-1" className="form-label">New Password</label>
            <input type="text" value={new_password} onChange={(e)=>setnew_password(e.target.value)} className='form-control' placeholder='New Password' />
          </div>
        </div>
      </div>


    

     
      
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary waves-effect" >Close</button>
      {/* <button type="button" className="btn btn-info waves-effect waves-light" onClick={handleSubmit(Submit)}>Save changes</button> */}
      {loading? <button className="btn btn-info waves-effect waves-light" disabled> <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> Saving...</button> :
        <button className="btn btn-info waves-effect waves-light" type="submit" onClick={()=>Update_Password()}  > Save </button>}
    </div>


        </Modal.Body>
        
      </Modal>
   
  </div>

  <Snackbar
        open={alert.open}
        TransitionComponent={Collapse}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={AlertClose}
      >
        <Alert onClose={AlertClose} severity={alert.severity}>
          {alert.msg}
        </Alert>
      </Snackbar>



</div>

  )
}

export default ProfilePage